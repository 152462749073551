<template>
  <div
    class="app-design-page fullscreen"
    v-loading="loading"
    :style="leftBarWidth"
  >
    <el-container class="layout">
      <el-header class="menu-bar" style="height: 54px">
        <!-- 顶部菜单栏 -->
        <page-header-content
          v-show="!isShowBackup"
          v-model="isShowTemplate"
          @header-click="handleHeaderClick"
        >
        </page-header-content>
        <!-- 备份顶部菜单 -->
        <div v-if="isShowBackup" class="page-header-backup flex-between">
          <h4>备份还原</h4>
          <i
            @click="isShowBackup = false"
            class="el-icon el-icon-close cursor-pointer"
            style="font-size: 24px"
          />
        </div>
      </el-header>
      <el-container v-show="!isShowTemplate && !isShowBackup" class="workspace">
        <div
          class="workspace-left"
          style="width: auto; max-width: 50%; min-width: var(--left-barwidth)"
        >
          <!-- 左侧菜单 -->
          <div class="resize-bar" />
          <div class="resize-line" />
          <div class="resize-save">
            <page-left
              v-model="main.selected"
              :list="main.componentList"
              :setting="main.setting"
              @switch-page="handleSwitchPage"
              @copy-page-config="copyPageConfig"
              @copy-page-id="copyPageID"
            >
            </page-left>
          </div>
        </div>
        <el-main class="workspace-middle">
          <page-middle
            v-model="main.selected"
            :page="main.page"
            :list="main.componentList"
            :system="main.system"
            :setting="main.setting"
            :rules-arr="rulesArr"
            @update-list="updateList($event, 'middle')"
            @hasTabPage="
              (e) => {
                isTabPage = e;
              }
            "
          />
        </el-main>
        <el-aside
          class="workspace-right"
          style="width: 360px"
          :class="{ disabled: pageRightDisabled }"
        >
          <page-right
            ref="pageRight"
            :selected="main.selected"
            :page="main.page"
            :list="main.componentList"
            :system="main.system"
            :setting="main.setting"
            @update-list="updateList($event, 'right')"
            @update-page="main.page = $event"
            @form-editor-update="rulesArr = $event"
          />
        </el-aside>
      </el-container>
      <el-container class="workspace">
        <!-- 单页使用相当于新增一个页面 -->
        <TemplatePage
          v-if="isShowTemplate"
          @update-location-search="updateLocationSearch"
          @switch-page="switchPage"
        />
        <BackupPage
          v-else-if="isShowBackup"
          @update-location-search="updateLocationSearch"
          @close="isShowBackup = false"
        />
      </el-container>
    </el-container>
    <!-- 预览二维码弹窗 -->
    <preview-code-dialog v-model="openPreviewDialog" :post-data="postData" />
    <publish-code-dialog
      v-model="showPublishCode"
      :code="publicCode"
      :link="publicLink"
    />
    <!-- 顶部 - 导入 -->
    <el-dialog
      title="提示"
      class="dialog-vertical"
      :visible="importDialog.show"
      width="450px"
      append-to-body
      close-on-click-modal
      @close="importDialog.show = false"
    >
      <p>
        该功能为导入{{ importDialog.platformText }}预览版的<span
          style="color: red"
          >设计样式和所有数据</span
        >。确定要导入吗？
      </p>
      <template #footer>
        <el-button
          size="small"
          :loading="importDialog.directLoading"
          :disabled="importDialog.loading"
          @click="importDirectly(true)"
          >直接导入</el-button
        >
        <el-button
          size="small"
          type="primary"
          :loading="importDialog.loading"
          :disabled="importDialog.directLoading"
          @click="importDirectly(false)"
          >备份并导入</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  createPage,
  getPageDetail,
  savePageDetail,
  syncProduct,
  checkMpWxStatus,
  undoCommit,
  switchYearTheme,
} from "../api/page";

/* 比较两个对象所有属性是否相同，相同为 true */
import Compare from "../../../base/utils/object-compare";

import { mapState } from "vuex";
import { copyText } from "@/base/utils/tool";

import PageHeaderContent from "../components/Page/PageHeaderContent";
import PageLeft from "../components/Page/PageLeft";
import PageMiddle from "../components/Page/PageMiddle";
import PageRight from "../components/Page/PageRight";
import PreviewCodeDialog from "../components/Page/PreviewCodeDialog.vue";
import TemplatePage from "../components/Page/TemplatePage";
import { formatConfigData } from "@/modules/app-design/assets/js/tool";
import PublishCodeDialog from "@/modules/app-design/components/Page/PublishCodeDialog";
import BackupPage from "../components/Page/BackupPage";
import { importConfig } from "@/modules/app-design/api/page-header";

let systemDict = require("../assets/jsons/system-page.json");
let library = require("../assets/jsons/component-library.json");
let { setting, page, system } = JSON.parse(JSON.stringify(library));

export default {
  components: {
    BackupPage,
    PublishCodeDialog,
    PageHeaderContent,
    PageLeft,
    PageMiddle,
    PageRight,
    PreviewCodeDialog,
    TemplatePage,
  },
  // 注入
  provide() {
    return {
      postData: this.postData,
      switchPage: (query, options) => this.handleSwitchPage(query, options),
      // getInnerPages: () => this.innerPages
    };
  },
  data() {
    return {
      postData: {
        project_id: "",
        pageset: "preview",
      },
      openPreviewDialog: false,
      publicLink: "",
      publicCode: "",
      showPublishCode: false,
      main: {
        // 如：'page', 'bottomNav', 'list', Number
        selected: -1,
        componentList: [],
        // 页面设置
        page: JSON.parse(JSON.stringify(page)),
        // 系统页面
        system: {},
        // 系统设置
        setting: {
          bottomWatermark: JSON.parse(JSON.stringify(setting)).bottomWatermark,
          theme: JSON.parse(JSON.stringify(setting)).theme,
          headerJump: JSON.parse(JSON.stringify(setting)).headerJump,
          internet_content_provider: JSON.parse(JSON.stringify(setting)).internet_content_provider
        },
      },
      // 记录该页面是否有底部导航
      isTabPage: false,
      // 是否保存失败，如果保存失败，不允许发布
      saveError: false,
      /* 调整左侧边栏整体默认宽度 */
      // leftBarWidth: "--left-barwidth: " + "265px" + "; ",
      leftBarWidth: "--left-barwidth: " + "300px" + "; ",
      // 是否显示备份
      isShowBackup: false,
      importDialog: {
        show: false,
        platformText: this.$route.params.platform === "h5" ? "小程序" : "H5 微网站",
        loading: false,
        directLoading: false,
      },
      // 页面装修小程序的时候是否允许保存，处于审核状态的小程序不允许保存
      isAllowSave: true,

      rulesArr: [],

      // 不需要了，先躺在这里
      // innerPages: {} // 由 page key(id | system_page) 和 page config(props) 组成的键值对
    };
  },
  computed: {
    /* 页面（自定义、系统）详情、全局loading、系统设置列表 */
    ...mapState("appDesign", [
      "pageDetail",
      "indexPageDetail",
      "resetPageDetail",
      "loading",
      "backupJson",
      "bottomNavBackupJson",
      "systemList",
      "library",
      "showTemplate",
      "componentListConfig",
      "isUseCustomIndex",
    ]),
    isShowTemplate: {
      get() {
        return this.showTemplate;
      },
      set(val) {
        this.$store.commit("appDesign/setShowTemplate", val);
      },
    },
    pageRightDisabled() {
      if (this.pageDetail && this.pageDetail.system_page === "index") {
        if (this.isUseCustomIndex) return true;
      }
      return false;
    },
  },
  watch: {
    /* 页面管理页面数据（自定义和系统） */
    pageDetail(val, oldVal) {
      /**
       * 重置 组件库组件使用计数 extra.count
       */
      this.resetCount();

      if (!val && oldVal) {
        // 还原初始数据
        this.main.page = JSON.parse(JSON.stringify(library)).page;
        this.main.componentList = [];

        /** 重置自由容器子组件下标 */
        this.componentListConfig.free_container_select = -1;
        return;
      }

      if (!val) {
        // TODO: 数据不存在？？
        // 还原初始数据
        this.main.page = {};
        this.main.componentList = [];

        /** 重置自由容器子组件下标 */
        this.componentListConfig.free_container_select = -1;

        this.main.selected = -1
        console.log('no pageDetail')
        return
      }

      if (val) {
        /* 如果是修改页面，pageDetail的标识没有改变 */
        const isModify =
          !!oldVal &&
          ((!val.system_page && val.id === oldVal.id) ||
            (val.system_page && val.system_page === oldVal.system_page));
        if (isModify) {
          // console.log('watch pageDetail time', val.update_time, oldVal.update_time)
          /** 仅更新了修改时间，不需要重置数据 */
          if (val.update_time !== oldVal.update_time) {
            if (val.config && val.config.list) {
              this.main.componentList.forEach((item, index) => {
                /* 需要更新组件版本 */
                this.main.componentList[index].version =
                  val.config.list[index].version;

                // 补：刷新组件使用次数，上面调用了resetCount，而这里稍后return了
                this.updateCount(item.component);
              });
            }
            if (val.system_page) {
              let selectedName = systemDict[val.system_page] || val.system_page;
              let sysObj = this.main.system[selectedName];
              let setObj = this.main.setting[selectedName];
              if (sysObj) {
                sysObj.version = val.config.page.version;
              } else if (setObj) {
                setObj.version = val.config.page.version;
              }
            } else {
              if (val.config && val.config.page) {
                this.main.page.version = val.config.page.version;
              }
            }
            return;
          }
        }

        if (val.config) {
          if (val.config.page) {
            if (val.system_page) {
              let selectedName = systemDict[val.system_page] || val.system_page;
              let obj =
                this.main.system[selectedName] ||
                this.main.setting[selectedName];
              if (obj) {
                this.main.system[selectedName] = Object.assign({}, obj, {
                  /* 经过合并后的数据更新到 system[selectedName] 上 */
                  ...val.config.page,
                  selectedName,
                  system_page: val.system_page,
                });
                this.main.selected = selectedName;
              }

              /* 获取部分系统页相关选项配置 */
              switch (val.system_page) {
                case "me_new":
                  this.$store.dispatch("appDesign/getMePageLinkOptions");
                  break;
                case "memberIndex":
                  this.$store.dispatch("appDesign/getMemberIndexQuickEnters");
                  break;
                default:
                  break;
              }
            } else {
              this.main.page = JSON.parse(JSON.stringify(val.config.page));
              this.main.page.selectedName = "page";
              this.main.selected = "page";
            }
          }

          if (val.config.list) {
            let componentList = [];
            val.config.list.forEach((item, index) => {
              componentList.push({
                ...item,
                /* 不需要保存的一些辅助参数 */
                extra: {
                  timestamp: new Date().getTime() + index * index,
                },
              });
              this.updateCount(item.component);
            });
            this.main.componentList = componentList;
          }
        } else {
          if (!val.system_page) {
            this.main.page = JSON.parse(JSON.stringify(library)).page;
          }
          this.main.componentList = [];
        }

        if (val.system_page) {
          /* 系统的组件列表 */
          if (val.config?.list) {
            if (!val.config.page && this.main.componentList.length)
              this.main.selected = 0;
          }
        }
      }
    },
    resetPageDetail(val) {
      if (val) {
        this.handleSwitchPage({ id: this.pageDetail.id }, { init: true });
      }
    },
  },
  created() {
    console.log("created in page.vue");
    // 赋值postData 并注入子组件
    this.postData.project_id = this.$route.params.id;
    this.postData.pageset = this.$route.query.pageset || "preview";
    // 项目检查小程序状态
    // 只在 platform=mp-weixin 且 pageset=preview 时请求
    if (
      this.$route.params.platform === "mp-weixin" &&
      this.postData.pageset === "preview"
    ) {
      this.checkMpStatus();
    }
    this.$bus.on('switch-year-theme', (obj) => {
      console.log(obj.style, this.main.setting.theme.props.otherConfig.style)
      if (obj.style === this.main.setting.theme.props.otherConfig.style) return
      console.log('执行主题更换接口，并刷新主题配置')
      this.$store.commit("appDesign/setLoading", true);
      switchYearTheme({
        "project_id": this.$route.params.id,
        "pageset": this.$route.query.pageset || "preview",
        ...obj
      }).then(async res => {
        this.main.selected = -1;
        await this.initSystemBottomNav();
        /* 初始获取主题系统设置 */
        await this.getSetting(
          { selectedName: "theme", id: 0, system_page: "theme" },
        );
        this.$message.success(res.msg);
      }).catch(() => {
        this.$store.commit("appDesign/setLoading", false);
      })
    })
  },
  mounted() {
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    // 在即将离开当前页面执行
    window.onbeforeunload = function (e) {
      e = e || window.event; // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "当前页面有修改，是否放弃更改？";
      } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "当前页面有修改，是否放弃更改？";
    };
  },
  // 清除bus监听
  beforeDestroy() {
    console.log("before destroy in page.vue");
    this.$bus.off("allowGetPageDetail");
    this.$bus.off("marginTopChange");
    this.$bus.off('switch-year-theme')
  },
  methods: {
    // 检查小程序状态
    checkMpStatus() {
      checkMpWxStatus().then((res) => {
        // 是否处于审核状态,审核状态不允许保存
        this.isAllowSave = res.data;
        // 该项目不允许保存
        if (!this.isAllowSave) {
          // 显示不允许保存弹窗
          this.$confirm(res.msg, "提示", {
            // 区分取消和关闭
            distinguishCancelAndClose: true,
            confirmButtonText: "我知道了",
            cancelButtonText: "撤回审核",
            type: "warning",
          })
            .then(() => {})
            .catch((action) => {
              if (action === "cancel") {
                undoCommit().then((res) => {
                  this.$message({
                    type: "success",
                    message: "撤回审核成功",
                  }).catch((err) => {
                    this.$message({
                      type: "error",
                      message: "撤回审核失败",
                    });
                  });
                });
              }
            });
        }
      });
    },
    /**
     * 点击左侧的页面管理或者页面管理初始化完成后触发
     * @param params
     * @param options
     * @returns {Promise<void>}
     */
    async handleSwitchPage(params, options) {
      if (options?.init) {
        this.main.system = this.library.system
        // 提前请求底部导航系统页
        await this.initSystemBottomNav();

        /* 初始获取主题、头部跳转配置、底部水印配置 */
        this.getPreSettings(params);
        // 获取页面（自定义页、系统页）配置
        await this.getDetail(params, options);
      } else {
        // 普通切换
        await this.switchPage(params, options);
      }

      // 不刷新页面修改地址栏参数
      if (!(options && options.newPage)) {
        let additionalQuery = {};
        if (params.id) {
          additionalQuery.id = params.id;
        } else if (params.system_page) {
          /* 过滤掉 系统设置 */
          let settingArr = [];
          let allSettings = Object.keys(library.setting);
          allSettings.forEach((s) => {
            settingArr.push(library.setting[s].system_page);
          });
          if (settingArr.includes(params.system_page)) {
            return;
          }
          additionalQuery.system_page = params.system_page;
        }
        this.updateLocationSearch(additionalQuery);
      }
    },
    /* 初始化页面时需要底部导航、主题、头部跳转配置数据 */
    getPreSettings(params) {
      // Notice: 三个系统设置同时请求，导致其中一个取消，需在api声明中设置不允许取消；

      // if (params.system_page && params.system_page === 'theme') {
      //   /* 无需重复请求，此时主题颜色作为系统页看待 */
      // } else {
      /* 初始获取主题系统设置 */
      this.getSetting(
        { selectedName: "theme", id: 0, system_page: "theme" },
        { init: true }
      );
      // }

      if (this.$route.params.platform !== "h5") {
        // if (params.system_page && params.system_page === 'header') {} else {
        /* 初始获取头部跳转系统设置 */
        this.getSetting(
          { selectedName: "headerJump", id: 0, system_page: "header" },
          { init: true }
        );
        // }
      }

      // if (params.system_page && params.system_page === 'watermark') {} else {
      /* 初始获取品牌版权系统设置，某些页面显示时会用到 */
      this.getSetting(
        { selectedName: "bottomWatermark", id: 0, system_page: "watermark" },
        { init: true }
      );
      // }
    },
    /**
     * 重置计数
     */
    resetCount() {
      let newLibrary = JSON.parse(JSON.stringify(this.library));
      newLibrary["custom_components"].forEach((lib) => {
        lib.components.forEach((c, i) => {
          if (c.extra === undefined) {
            c.extra = {
              count: 0,
              timestamp: new Date().getTime() + i * i,
            };
          } else {
            c.extra.count = 0;
          }
        });
      });
      this.$store.commit("appDesign/setLibrary", newLibrary);
    },
    /***
     * 增加组件使用次数
     * @param{String} component 组件标识
     */
    updateCount(component) {
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library));
      newLibrary["custom_components"].forEach((lib) => {
        lib.components.forEach((c) => {
          if (c.component === component) {
            if (c.extra) c.extra.count++;
          }
        });
      });
      this.$store.commit("appDesign/setLibrary", newLibrary);
    },
    /**
     * 保存、切换通用方法
     * @params{Object}  存放要跳转的id或system_page标识
     * @params{Boolean}  是否初始化状态，初始化时不需要保存上次的，还有其他参数略
     * */
    switchPage: async function (params, options = {}) {
      // console.log("switchPage", params, options);
      /**
       * options.init 为true代表首次进入装修页面，仅获取配置数据，不需要保存或切换当前页面
       */
      if (options.init || options.refresh) {
        await this.getDetail(params, options);
        return;
      }

      /* 底部导航是否修改 */
      let bottomNavIsModify = this.isModify("bottomNav");
      console.log(bottomNavIsModify, "bottom nav is modified? ");

      /** 是否新增页面 */
      let isAddPage = !!options.newPage;

      /** 当前正在编辑的是页面管理还是系统设置 */
      let nowIsPage = this.pageDetail != null;

      /** 是否仅保存
       * 非仅保存情况可能有：新增页面，切换页面，切换系统设置等，
       * 仅保存，params参数为null或者undefined？*/
      let saveOnly = !params;

      /* 判断当前是否有修改 是否需要保存 */
      let needSave = true;
      /* 是否有页面/系统设置正在编辑 */
      if (this.main.selected === -1) {
        // -1 就是页面不存在，被删除了，自然不需要保存了
        needSave = false;
      } else if (nowIsPage) {
        // 自定义页/系统页
        needSave = this.isModify();
      } else {
        // 系统设置
        needSave = this.isModify(this.main.selected);
      }

      if (needSave) {
        console.log("检测到当前页面/系统设置修改");
      }

      if (saveOnly) {
        /** 仅保存 */
        if (nowIsPage) {
          if (this.pageDetail.system_page !== "index") {
            if (bottomNavIsModify) await this.saveSystemBottomNav();
          }
          await this.saveDetail(options.hideMsg);
        } else {
          if (this.main.selected !== "bottomNav") {
            if (bottomNavIsModify) await this.saveSystemBottomNav();
          }
          /* 点保存、发布按钮，无论有无修改，一律保存 */
          await this.saveSetting(null, options.hideMsg);
        }
        return;
      }

      if (!isAddPage) {
        /** 重复点击相同页面 */
        let noSwitch = false;
        let currentSystemPage = this.pageDetail?.system_page;
        let currentId = this.pageDetail?.id;
        if (
          params.system_page &&
          currentSystemPage &&
          params.system_page === currentSystemPage
        ) {
          noSwitch = true;
        } else if (params.id && currentId && params.id === currentId) {
          noSwitch = true;
        }
        if (noSwitch) {
          this.$message.info("已经是当前页面了");
          return;
        }
      }

      /* 没有修改，不需要保存 */
      if (!needSave) {
        console.log("当前没有修改");

        if (bottomNavIsModify) await this.saveSystemBottomNav();

        if (isAddPage) {
          /* 新增页面 */
          this.createNewPage(options.newPage);
        } else {
          /* 切换 */
          await this.getDetail(params, options);
        }
        return;
      }

      /** 先询问是否保存，再新增或者切换页面 */
      /* 修改了系统设置 */
      if (!nowIsPage) {
        this.$confirm("是否保存当前系统设置？", "提示", {
          confirmButtonText: "保存",
          cancelButtonText: "不保存",
          type: "info",
          distinguishCancelAndClose: true,
          callback: async (action) => {
            if (action === "cancel") {
            } else if (action === "close") {
              return;
            } else if (action === "confirm") {
              if (this.main.selected !== "bottomNav") {
                if (bottomNavIsModify) await this.saveSystemBottomNav();
              }
              /* 先保存 系统设置 */
              this.$store.commit("appDesign/setLoading", true);
              await this.saveSetting(null, options.hideMsg);
            }
            if (isAddPage) {
              this.createNewPage(options.newPage);
            } else {
              await this.getDetail(params, options);
            }
          },
        });
      } else {
        /* 修改了页面设置 */
        this.$confirm(
          "是否保存当前页面的设置？切换页面后无法进行撤销及恢复操作",
          "提示",
          {
            confirmButtonText: "保存",
            cancelButtonText: "不保存",
            type: "info",
            distinguishCancelAndClose: true,
            callback: async (action) => {
              if (action === "cancel") {
              } else if (action === "close") {
                return;
              } else if (action === "confirm") {
                if (this.pageDetail.system_page !== "index") {
                  if (bottomNavIsModify) await this.saveSystemBottomNav();
                }
                this.$store.commit("appDesign/setLoading", true);
                /* 先保存 */
                if (needSave) {
                  await this.saveDetail(options.hideMsg);
                }
              }
              /* 无论确定还是取消都会执行如下代码 */
              if (isAddPage) {
                /* 新增 */
                this.createNewPage(options.newPage);
              } else {
                /* 切换 */
                await this.getDetail(params, options);
              }
            },
          }
        );
      }
    },
    /**
     * 判断是否修改
     * @param selectedName  isPage是系统设置时要传
     */
    isModify(selectedName) {
      let isPage = !selectedName;
      if (isPage && !this.pageDetail) return false;

      let backup;
      let page;
      let list;

      let systemPage = "";

      if (isPage) {
        systemPage = this.pageDetail && this.pageDetail.system_page;
        backup = this.backupJson ? JSON.parse(this.backupJson) : {};
        if (systemPage) {
          selectedName = selectedName || systemDict[systemPage] || systemPage;
          page =
            this.main.system[selectedName] || this.main.setting[selectedName];
        } else {
          page = this.main.page;
        }

        list = this.main.componentList;
      } else {
        if (selectedName === "bottomNav") {
          systemPage = "index";
          backup = this.bottomNavBackupJson
            ? JSON.parse(this.bottomNavBackupJson)
            : {};
          page = this.main.system[selectedName];
        } else {
          systemPage = isPage
            ? this.pageDetail.system_page
            : this.main.setting[selectedName].system_page;
          backup = this.backupJson ? JSON.parse(this.backupJson) : {};
          /* 检测系统设置是否修改 */
          page = this.main.setting[selectedName];
        }

        list = [];
      }

      // if (!isPage) {
      //   console.log('-------------------------------')
      //   console.log(selectedName)
      //   console.log('-------------------------------')
      //   console.log(this.main.setting[selectedName])
      //   console.log('-------------------------------')
      // }

      let config = formatConfigData(
        {
          page,
          list,
        },
        systemPage
      );

      return !Compare(backup, config);
    },
    /**
     * 新增页面，刷新列表并切换
     */
    createNewPage(params) {
      this.$store.commit("appDesign/setLoading", true);
      let config;
      if (params.config) {
        /* 单页使用，携带模板预设好的配置 */
        config = formatConfigData(params.config);
        /* 使用模板页面标题 */
        params.name = config.page.props.normal.titleConfig.name;
      } else {
        config = formatConfigData({
          page: {
            props: {
              normal: { titleConfig: { name: params.name, isShow: 1 } },
            },
          },
        });
        // config.page.props.normal.titleConfig.name = params.name;
      }

      createPage({
        ...params,
        config,
        ...this.postData,
      })
        .then((res) => {
          this.$store.commit("appDesign/setIsRefreshPages", true);
          this.switchPage({ id: res.data }, { init: true });
          this.updateLocationSearch({ id: res.data });
        })
        .catch(() => {});
    },
    /**
     * 不刷新页面修改地址栏参数
     * @param {Object}  query
     * @param {Boolean} force 是否保留已有 currentRoute.query 参数
     */
    updateLocationSearch(query, force = false) {
      /* 过滤掉 id、system_page 参数，因链接中只能存在其中一个 */
      let currentQueryFiltered;
      let currentQuery = this.$router.currentRoute.query;
      if (currentQuery.system_page) {
        let { system_page, ...filtered } = currentQuery;
        currentQueryFiltered = filtered;
      } else {
        let { id, ...filtered } = currentQuery;
        currentQueryFiltered = filtered;
      }

      /* 避免重复 replace 相同的路由 */
      if (query.id && query.id === currentQuery.id) {
        return;
      } else if (
        query.system_page &&
        query.system_page === currentQuery.system_page
      ) {
        return;
      }

      /** @deprecated */
      // let routerData = this.$router.resolve({
      //   name: "AppDesignPage",
      //   params: this.$router.currentRoute.params,
      //   // 已有的 query 参数要保留
      //   query: Object.assign({}, currentQueryFiltered, additionalQuery),
      // });
      // window.history.replaceState("", "", routerData.href);

      this.$router
        .replace({
          name: "AppDesignPage",
          // 原有的 id（project_id）、platform 保留
          params: this.$router.currentRoute.params,
          // 已有的 query 参数要保留（如 pageset）
          query: !force
            ? Object.assign({}, currentQueryFiltered, query)
            : query,
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 获取页面（自定义页、系统页）设置或系统设置
     */
    getDetail: async function (params, options) {
      /**
       * 不传params参数表示仅保存当前配置，不切换，如顶部菜单栏的保存按钮
       * */
      if (!params) return;

      let isSwitchPage = !params.selectedName;
      if (isSwitchPage) {
        this.$store.commit("appDesign/setLoading", true);
        await this.$store.dispatch("appDesign/getPageDetail", {
          requestData: { ...params, ...this.postData },
          callback: () => {
            setTimeout(() => {
              /* pageDetail watcher 的处理也需要时间 */
              this.$store.commit("appDesign/setLoading", false);
            }, 500);
          },
        });
      } else {
        /* 若传入init为true，则不自动修改main.selected，此专门适配底部导航初始化获取的 */
        await this.getSetting(params, options);
      }
    },
    async saveSystemBottomNav() {
      let pageDetail = this.indexPageDetail;
      let params = {
        ...pageDetail,
        config: formatConfigData(
          {
            page: this.main.system["bottomNav"],
          },
          "index",
          true
        ),
      };
      if (!params || params.update_time === undefined) {
        if (this.loading) this.$store.commit("appDesign/setLoading", false);
        return;
      }
      try {
        if (!this.loading) {
          this.$store.commit("appDesign/setLoading", true);
        }
        /* 更新修改时间 */
        let res = await savePageDetail({
          ...params,
          ...this.postData,
        });
        // 不允许保存 ,data 返回 false 表示不能保存
        if (!res.data) {
          this.$store.commit("appDesign/setLoading", false);
          // 显示不允许保存弹窗
          this.$confirm(
            "当前小程序正处于「审核」状态，无法预览、保存修改的内容，如需要预览、保存，请先撤回审核",
            "提示",
            {
              // 区分取消和关闭
              distinguishCancelAndClose: true,
              confirmButtonText: "我知道了",
              cancelButtonText: "撤回审核",
              type: "warning",
            }
          )
            .then(() => {})
            .catch((action) => {
              if (action === "cancel") {
                undoCommit().then((res) => {
                  this.$message({
                    type: "success",
                    message: "撤回审核成功",
                  }).catch((err) => {
                    this.$message({
                      type: "error",
                      message: "撤回审核失败",
                    });
                  });
                });
              }
            });
          // 退出
          return;
        }

        /* 保存 底部导航-系统页 成功，更新备份 */
        // this.$store.commit("appDesign/setStateAttr", [
        //   {
        //     key: "bottomNavBackupJson",
        //     val: JSON.stringify(params.config),
        //   },
        //   {
        //     key: "indexPageDetail",
        //     val: { ...pageDetail, update_time: result.data },
        //   },
        // ]);
        // console.log('bottom nav backup json updated. --save')
        await this.initSystemBottomNav();
        if (this.loading) {
          this.$store.commit("appDesign/setLoading", false);
        }
        this.saveError = false;
      } catch (e) {
        this.saveError = true;
        if (this.loading) {
          this.$store.commit("appDesign/setLoading", false);
        }
      }
    },
    /**
     * 提前请求底部导航系统页
     */
    initSystemBottomNav() {
      return new Promise((resolve, reject) => {
        getPageDetail({
          id: 0,
          system_page: "index",
          ...this.postData,
        })
          .then((res) => {
            let finalConfig = formatConfigData(res.data.config, "index");

            this.$store.commit("appDesign/setStateAttr", [
              {
                key: "bottomNavBackupJson",
                val: JSON.stringify(finalConfig),
              },
              {
                key: "indexPageDetail",
                val: res.data,
              },
            ]);

            this.$set(this.main.system, "bottomNav", {
              ...finalConfig.page,
              system_page: "index",
              selectedName: "bottomNav",
            });
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    },
    /**
     * 获取系统设置详情
     * */
    getSetting: function ({ selectedName, index, ...data }, options = {}) {
      return new Promise((resolve, reject) => {
        if (!options.init) {
          this.$store.commit("appDesign/setLoading", true);
        }
        getPageDetail({ ...data, ...this.postData })
          .then((res) => {
            let systemList = this.systemList;
            /* 加入了 selectedName 属性的 pageDetail，TODO: 后序优化 selectedName 为 system_page */
            let current = options.init
              ? { selectedName, ...res.data }
              : Object.assign({}, systemList[index], res.data);
            /** 排除掉 config 字段，systemList 只保留简单的字段就好 */
            let { config, ...others } = current;
            let finalConfig = formatConfigData(config, data.system_page);
            if (options.init) {
              /* 初始获取系统设置内容 */
              index = systemList.findIndex(
                (el) => el.system_page === data.system_page
              );
            } else {
              /** 点击系统设置列表 */
              this.$store.commit(
                "appDesign/setBackupJson",
                JSON.stringify(finalConfig)
              );
              // 保存时需要的update_time信息，不要config字段
            }
            systemList[index] = others;
            this.$store.commit("appDesign/setSystemList", systemList);

            this.$set(this.main.setting, selectedName, {
              ...finalConfig.page,
              system_page: data.system_page,
              selectedName: selectedName,
            });

            if (!options.init) {
              this.$store.commit("appDesign/setPageDetail", null);
              this.main.selected = selectedName;
            }

            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          })
          .finally(() => {
            if (!options.init) {
              this.$store.commit("appDesign/setLoading", false);
            }
          });
      });
    },
    async saveSetting(selected, hideMsg) {
      selected = selected || this.main.selected;
      let index = this.systemList.findIndex(
        (it) => it.selectedName === selected
      );
      if (index === -1) {
        console.log("系统设置不存在");
        if (this.loading) this.$store.commit("appDesign/setLoading", false);
        return;
      }

      let { selectedName, ...pageDetail } = this.systemList[index];

      let params = {
        ...pageDetail,
        config: formatConfigData(
          { page: this.main.setting[selected] },
          this.main.setting[selected].system_page,
          true
        ),
      };
      if (!params || params.update_time === undefined) {
        if (this.loading) this.$store.commit("appDesign/setLoading", false);
        return;
      }

      // console.log('-----------------------------------')
      // console.log(params)
      // console.log('-----------------------------------')
      // console.log(Compare(JSON.parse(this.backupJson), params.config))
      // console.log('-----------------------------------')

      if (!hideMsg) {
        this.$store.commit("appDesign/setLoading", true);
      }
      try {
        /* 更新修改时间 */
        let result = await savePageDetail({
          ...params,
          ...this.postData,
        });
        console.log(result, "result");
        // 不允许保存 ,data 返回 false 表示不能保存
        if (!result.data) {
          this.$store.commit("appDesign/setLoading", false);
          // 显示不允许保存弹窗
          this.$confirm(
            "当前小程序正处于「审核」状态，无法预览、保存修改的内容，如需要预览、保存，请先撤回审核",
            "提示",
            {
              // 区分取消和关闭
              distinguishCancelAndClose: true,
              confirmButtonText: "我知道了",
              cancelButtonText: "撤回审核",
              type: "warning",
            }
          )
            .then(() => {})
            .catch((action) => {
              if (action === "cancel") {
                undoCommit().then((res) => {
                  this.$message({
                    type: "success",
                    message: "撤回审核成功",
                  }).catch((err) => {
                    this.$message({
                      type: "error",
                      message: "撤回审核失败",
                    });
                  });
                });
              }
            });
          // 退出
          return;
        }
        if (pageDetail.system_page === "index") {
          this.$store.commit("appDesign/setStateAttr", {
            key: "bottomNavBackupJson",
            val: JSON.stringify(params.config),
          });

          console.log("bottom nav backup json updated. --save setting");
        } else {
          this.$store.commit(
            "appDesign/setBackupJson",
            JSON.stringify(params.config)
          );
        }
        let list = this.systemList;
        list[index].update_time = result.data;
        this.$store.commit("appDesign/setSystemList", list);
        if (!hideMsg) {
          this.$message.success("保存成功");
        }
        this.saveError = false;
      } catch (e) {
        this.saveError = true;
      } finally {
        if (!hideMsg) {
          this.$store.commit("appDesign/setLoading", false);
        }
      }
    },
    /**
     * 保存页面配置(自定义页面和系统页面)
     * 更新版本号和数据结构
     **/
    async saveDetail(hideMsg) {
      try {
        let selectedName =
          systemDict[this.pageDetail.system_page] ||
          this.pageDetail.system_page;
        let page = this.pageDetail.system_page
          ? this.main.system[selectedName] || this.main.setting[selectedName]
          : this.main.page;
        // console.log('-----------------------------------')
        // console.log(page)
        // console.log('-----------------------------------')

        // TODO：这里有个意外情况，st-custom-header 会出现好几个的情况
        // const list = JSON.parse(JSON.stringify(this.main.componentList))
        const list = this.main.componentList;

        checkMultiHeader()

        function checkMultiHeader() {
          const isMultiHeader = list.filter(li => li.component === 'st-custom-header').length > 1
          if (isMultiHeader) {
            let index = list.findIndex(li => li.component === 'st-custom-header')
            if (index !== -1) {
              list.splice(index, 1)
              checkMultiHeader() // 看看还有没有
            } else {
              // 没有
            }
          } else {
            // 没有
          }
        }

        let config = formatConfigData(
          { page, list },
          this.pageDetail.system_page,
          true
        );
        // console.log('-----------------------------------')
        // console.log(config)
        // console.log('-----------------------------------')
        if (!config) {
          if (this.loading) this.$store.commit("appDesign/setLoading", false);
          return;
        }
        let params = {
          ...this.pageDetail,
          config: config,
          ...this.postData,
        };

        // console.log('-----------------------------------')
        // console.log(params)
        // console.log('-----------------------------------')
        // console.log(Compare(JSON.parse(this.backupJson), config))
        // console.log('-----------------------------------')

        this.$store.commit("appDesign/setLoading", true);
        let res = await savePageDetail(params);
        // 不允许保存 ,data 返回 false 表示不能保存
        if (!res.data) {
          this.$store.commit("appDesign/setLoading", false);
          // 显示不允许保存弹窗
          this.$confirm(
            "当前小程序正处于「审核」状态，无法预览、保存修改的内容，如需要预览、保存，请先撤回审核",
            "提示",
            {
              // 区分取消和关闭
              distinguishCancelAndClose: true,
              confirmButtonText: "我知道了",
              cancelButtonText: "撤回审核",
              type: "warning",
            }
          )
            .then(() => {})
            .catch((action) => {
              if (action === "cancel") {
                undoCommit().then((res) => {
                  this.$message({
                    type: "success",
                    message: "撤回审核成功",
                  }).catch((err) => {
                    this.$message({
                      type: "error",
                      message: "撤回审核失败",
                    });
                  });
                });
              }
            });
          // 退出
          return;
        }
        /* 保存成功，更新备份的数据 */
        this.$store.commit("appDesign/setBackupJson", JSON.stringify(config));

        if (params.system_page === "index") {
          /* 更新系统设置-底部导航的修改时间 */
          let indexPageDetail = this.indexPageDetail;
          /* 如果保存的是底部导航系统页，还需要更新底部导航数据备份 */
          this.$store.commit("appDesign/setStateAttr", [
            {
              key: "bottomNavBackupJson",
              val: JSON.stringify(config),
            },
            {
              key: "indexPageDetail",
              val: { ...indexPageDetail, update_time: res.data },
            },
          ]);

          console.log("bottom nav backup json updated. --save");
        }

        let detail = {
          ...params,
          /* 刷新修改时间 */
          update_time: res.data,
        };

        console.log(
          "-------------",
          params.id,
          params.system_page,
          "-------------"
        );
        console.log("update_time before: ", this.pageDetail.update_time);
        console.log("update_time after: ", detail.update_time);
        console.log("--------------------------");

        /** 如果`自定义页面`的 页面设置 - 标题 配置有修改 */
        if (
          this.pageDetail.id &&
          config.page &&
          config.page.props.normal &&
          config.page.props.normal.titleConfig &&
          config.page.props.normal.titleConfig.name
        ) {
          /* 如果修改了常规设置 - 页面标题，需要刷新页面列表 */
          let isChangePageTitle = false;
          isChangePageTitle =
            config.page.props.normal.titleConfig.name !==
            this.pageDetail.config.page.props.normal.titleConfig.name;
          if (isChangePageTitle) {
            this.$store.commit("appDesign/setIsRefreshPages", true);
          }
        }

        if (!hideMsg) {
          this.$message.success(res.msg);
        }

        // 更新修改时间，随即触发 watcher
        this.$store.commit("appDesign/setPageDetail", detail);

        this.saveError = false;
      } catch (err) {
        console.log(err);
        this.saveError = true;
      } finally {
        this.$store.commit("appDesign/setLoading", false);
      }
    },
    /* 点击顶部菜单按钮 */
    async handleHeaderClick(key, msg) {
      switch (key) {
        case "import":
          this.importDialog.show = true;
          break;
        case "save":
          await this.switchPage();
          break;
        case "publish":
          if (msg) console.log(msg);
          await this.switchPage(null, { hideMsg: true });
          if (!this.saveError) {
            this.$store.commit("appDesign/setLoading", true);
            syncProduct(this.postData)
              .then((res) => {
                if (res.data) {
                  if (this.$route.params.platform === "h5") {
                    this.publicCode = res.data.qrcode;
                    this.publicLink = res.data.link;
                    this.showPublishCode = true;
                  } else {
                    this.$message.success(res.msg);
                  }
                } else {
                  this.$msgbox
                    .confirm(res.msg, "提示", {
                      type: "info",
                      confirmButtonText: "撤回审核",
                    })
                    .then((res) => {
                      // 不跳路由了，直接撤回审核
                      // let routerData = this.$router.resolve({
                      //   name: "VersionSetting",
                      // });
                      // window.open(routerData.href, "_blank");
                      undoCommit().then((res) => {
                        this.$message({
                          type: "success",
                          message: "撤回审核成功",
                        }).catch((err) => {
                          this.$message({
                            type: "error",
                            message: "撤回审核失败",
                          });
                        });
                      });
                    })
                    .catch((err) => {});
                }
                this.$store.commit("appDesign/setLoading", false);
              })
              .catch((err) => {
                this.$store.commit("appDesign/setLoading", false);
              });
          }
          break;
        case "preview":
          await this.switchPage(null, { preview: true, hideMsg: true });
          this.openPreviewDialog = true;
          break;
        case "backup":
          this.isShowBackup = true;
          break;
        case "exChangeModules":
          this.isShowTemplate = true;
          break;
        case "checkExample":
          this.$alert("正在努力开发中…", "提示", {
            confirmButtonText: "确定",
            /* 以 alert 方式调用时为 false） */
            closeOnClickModal: true,
            /* 设置closeOnClickModal为true后，不声明callback时会抛出uncaught Promise错误 */
            callback: () => {},
          });
          break;
      }
    },
    /**
     * 更新组件列表
     */
    updateList(arr, sign) {
      this.main.componentList = arr.map((el) => {
        /* 合并配置，根据 extra.timestamp 唯一标识查找旧配置（不能用下标查找，因为如果有新增或删除组件，新下标和旧下标可能不一致） */
        const old = this.main.componentList.find(c => c.extra.timestamp === el.extra.timestamp)
        if (old) return {...old, ...el};
        return el;
      });

      /* 数组发生排序，右侧监听不到变化 */
      // console.log(sign)
      // if (sign === 'middle') {
      //   console.log(this.$refs['pageRight'])
      /* 经测试，没有效果 */
      //   this.$refs['pageRight'].refreshList();
      // }
      this.resetCount()
      this.main.componentList.forEach(c => {
        this.updateCount(c.component)
      })
    },
    // 复制页面配置
    copyPageConfig() {
      let selectedName =
        systemDict[this.pageDetail.system_page] || this.pageDetail.system_page;
      let page = this.pageDetail.system_page
        ? this.main.system[selectedName] || this.main.setting[selectedName]
        : this.main.page;
      let config = formatConfigData(
        { page, list: this.main.componentList },
        this.pageDetail.system_page
      );
      copyText(JSON.stringify(config));
      this.$message.success("复制成功");
    },
    // 复制ID
    copyPageID(id) {
      copyText(id);
      this.$message.success("复制成功");
    },
    /**
     * 导入配置
     * @param {boolean} directly  是否不备份 true: 不备份 false: 备份
     */
    importDirectly(directly = false) {
      this.importDialog[directly ? "directLoading" : "loading"] = true;
      importConfig({
        ...this.postData,
        is_backup: !directly ? 1 : 0,
      })
        .then(({ msg }) => {
          this.importDialog[directly ? "directLoading" : "loading"] = false;
          this.importDialog.show = false;
          this.$message.info(msg);

          this.$store.commit("appDesign/setStateAttr", [
            { key: "pageDetail", val: null },
            { key: "backupJson", val: "" },
            { key: "bottomNavBackupJson", val: "" },
          ]);

          // 不刷新页面修改地址栏参数
          this.updateLocationSearch({});

          /** 它们俩的区别是，
           * 触发调用 getPages() 时 init 参数是否为 true，
           * 后者为 true；
           * 如果为 true，刷新页面数组后，
           * 假如地址栏参数有 id 或者 system_page，会自动切换到相应页面，
           * 否则，将自动切换至页面数组的第一个页面。
           */
          // this.$store.commit("appDesign/setIsRefreshPages", true)
          this.$bus.emit("allowGetPageDetail");
        })
        .catch((err) => {
          this.importDialog[directly ? "directLoading" : "loading"] = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-design-page {
  /*overflow-x: auto;*/
  margin: calc(-#{$space} * 2);
  background-color: white;

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;

    .workspace {
      height: calc(100vh - 54px);
    }
  }
}

.menu-bar {
  background-color: $primary;

  .page-header-backup {
    height: 100%;
    align-items: center;
    color: white;
    padding: 0 46px;
  }
}

.workspace-left {
  /*overflow-x: visible;*/
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  float: left;
}

/* 顶部菜单栏下面的一整个容器 */
.workspace {
  height: calc(100vh - 60px - 50px - 54px);
  overflow: hidden;
}

.el-main.workspace-middle {
  background-color: #eef2f8;
  padding: 0;
  /*overflow: hidden;*/
}

.workspace-right {
  min-width: 370px;
  width: 370px;

  &.disabled {
    position: relative;

    ::v-deep .page-right {
      overflow-y: hidden;
    }

    ::v-deep .disabled-mask {
      display: flex;
    }
  }
}

/* TEST */
.resize-save {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.resize-bar {
  width: var(--left-bar-width);
  min-width: var(--left-bar-width);
  max-width: 100%;
  /*height: inherit;*/
  height: calc(100vh - 54px);
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}

/* 拖拽线 */
.resize-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 2px solid #eee;
  border-left: 1px solid #bbb;
  pointer-events: none;
}

.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  border-left: 1px dashed #3a76ff;
}

.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: inherit;
}

/* Firefox只有下面一小块区域可以拉伸 */
@supports (-moz-user-select: none) {
  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px solid #bbb;
  }
  .resize-bar:hover ~ .resize-line:after,
  .resize-bar:active ~ .resize-line:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: -8px;
    /*background: url(./resize.svg);*/
    /*background-size: 100% 100%;*/
  }
}
</style>
