<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 在线学习组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      borderRadius: `${myConfig.normal.borderRadius}px`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 ${myStyleType !== 'template3' ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />
    <!-- 模版一 -->
    <div
      v-loading="loading"
      class="template1-data-list"
      v-if="['template1'].includes(myStyleType)"
    >
      <div class="item-list">
        <div
          class="item"
          v-for="(item, i) in dataList"
          :key="item.id"
          :style="{
            marginLeft: `${i > 0 ? myConfig.templateData.dataSpacing : 0}px`,
          }"
        >
          <img class="avatar" :src="item.avatar" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 模版二 -->
    <div
      v-loading="loading"
      class="template2-data-list"
      v-if="['template2'].includes(myStyleType)"
    >
      <div class="item-list">
        <div class="item" v-for="(item, i) in dataList" :key="item.id">
          <img class="avatar" :src="item.avatar" />
          <div class="right-content">
            <div class="name">{{ item.name }}</div>
            <div class="desc">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getTeacherList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'

export default {
  name: 'st-college-teacher-list',
  components: { StLoadImage },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          borderRadius: 12,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 12,
        },
        dataConfig: {
          dataType: 'select',
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      dataList: [],
    }
  },
  watch: {
    'myLoadingConfig.limit'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList(notCancel) {
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        tab: 'normal',
        limit: this.myLoadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        template: this.myStyleType,
        ids,
      }
      this.loading = true
      getTeacherList(requestData, notCancel)
        .then((res) => {
          const { list } = res.data
          this.dataList = list

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .template1-data-list {
    min-height: 100px;
    overflow: hidden;
    .item-list {
      width: max-content;
      display: flex;
      padding: 0 12px;
      .item {
        width: 150px;
        height: 193px;
        background-image: url('~@/modules/app-design/assets/images/st-college-teacher-list/teacher-bg.png');
        background-size: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .avatar {
          margin-top: 24.5px;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        .name {
          font-family: 'Dream Han Serif CN';
          font-weight: bold;
          margin-top: 8px;
          color: #292b2d;
          font-size: 16px;
        }
      }
      .item + .item {
        margin-left: 8px;
      }
    }
  }
  .template2-data-list {
    overflow: hidden;
    .item-list {
      padding: 0 12px;
      .item {
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 12px;
        background: #fff;
        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          object-fit: cover;
        }
        .right-content {
          margin-left: 12px;

          .name {
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            line-height: 24px;
          }
          .desc {
            margin-top: 6px;
            font-size: 13px;
            line-height: 19.5px;
            // min-height: 42px;
            @include clamp(2);
          }
        }
      }
      .item + .item {
        margin-top: 8px;
      }
    }
  }
}
</style>
