<template>
  <div
    :style="{
      // marginTop: hasCustomHeader || system_page === 'me_new' ? '-64px' : '',
      // marginTop: hasCustomHeader ? '-64px' : '',

      // transform: `scale(${ratio})`,
      backgroundImage:
        systemConfig &&
        systemConfig.props &&
        systemConfig.props.normal &&
        systemConfig.props.normal.bgType === 'image' &&
        !['contactUs'].includes(system_page)
          ? `url(${systemConfig.props.normal.bgImage})`
          : '',
      backgroundColor:
        (systemConfig &&
          systemConfig.props &&
          (systemConfig.props.normal &&
          systemConfig.props.normal.bgType === 'color'
            ? systemConfig.props.normal.bgColor
            : '')) ||
        (['me_new', 'memberIndex', 'contactUs'].includes(system_page)
          ? '#F4F5F6'
          : '#fff'),
    }"
    class="page-view"
    v-loading="loading"
  >
    <div
      style="padding: 12px"
      v-if="jump_link && isSinglePage(jump_link.jump_config)"
    >
      <tinymce
        :inline="true"
        :readonly="true"
        :show-article-import="false"
        :show-statusbar="false"
        :show-toolbar="false"
        ref="editor"
        v-model="form.content"
      ></tinymce>

      <!-- 文件下载 -->
      <div class="article-file-list" v-if="form.files && form.files.length">
        <template v-for="(file, index) in form.files">
          <div :key="file.url" class="download-item-box">
            <download-item
              :name="file.title"
              :type="file.type"
              :url="file.url"
            />
          </div>
        </template>
      </div>
    </div>
    <template v-else-if="list.length || system_page">
      <!-- 头部背景层 -->
      <!--
&&(!isH5 || (isH5 && hasCustomHeader))
-->
      <div
        :class="['drag-item no-hover']"
        :style="{
          height: page.props.header.height + (isH5 ? 0 : 64) + 'px',
          top: isH5 ? '64px' : 0,
        }"
        class="page-top"
        style="position: absolute;z-index:-1;"
        v-if="page.props && page.props.header"
      >
        <div
          :style="{
            height: 'inherit',
            overflow: 'hidden',
          }"
          class="header-fixed"
        >
          <!-- 背景层 -->
          <div
            :style="{
              height: 'inherit',
              backgroundColor:
                page.props.header.bgType === 'image'
                  ? ''
                  : page.props.header.bgColor || '',
              borderRadius: page.props.header.radian > 0 ? '0 0 50% 50%' : '0',
              width:
                page.props.header.radian > 0
                  ? 200 - page.props.header.radian * 1 + '%'
                  : '100%',
              marginLeft:
                page.props.header.radian > 0
                  ? -(200 - page.props.header.radian * 1) / 2 + '%'
                  : '-50%',
              opacity: 1 - page.props.header.opacity / 100,
            }"
            class="page-bg"
          >
            <el-image
              :src="page.props.header.bgImage"
              :style="{
                height: 'fit-content' || 'inherit',
              }"
              fit="contain"
              v-if="page.props.header.bgType === 'image'"
            />
          </div>
        </div>
      </div>

      <!-- 个人中心头部样式 -->
      <div
        :style="{
          height: (isH5 ? 135 : 135) + systemConfig.props.header.height + 'px',
          marginTop: isH5 ? '64px' : 0,
        }"
        class="me-bg-content"
        v-if="system_page === 'me_new'"
      >
        <div
          :style="{
            backgroundColor:
              systemConfig.props.header.bgType === 'color'
                ? systemConfig.props.header.bgColor
                : '',
            borderRadius:
              systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
            width:
              systemConfig.props.header.radian > 0
                ? 300 - systemConfig.props.header.radian * 2 + '%'
                : '100%',
            marginLeft:
              systemConfig.props.header.radian > 0
                ? -(300 - systemConfig.props.header.radian * 2) / 2 + '%'
                : '-50%',
            opacity: 1 - systemConfig.props.header.opacity / 100,
          }"
          class="me-bg"
        >
          <div
            :style="{
              backgroundImage: `url(${
                systemConfig.props.header.bgImage ||
                baseUrl + '/cyc/images/wxapps/images/thumb_up.png'
              })`,
            }"
            class="me-bg-image-box"
            v-if="systemConfig.props.header.bgType === 'image'"
          ></div>
          <!-- 不给它加遮罩层了，图片自己实现渐变吧 -->
          <div
            :style="{
              top: !isH5
                ? `${
                    // systemConfig.props.header.height + 80}px`
                    systemConfig.props.header.height + 135 - 67
                  }px`
                : `${systemConfig.props.header.height + 135 - 67}px`,
            }"
            class="template3-bg flex"
            style="position: absolute; width: 375px"
            v-if="
              false &&
              systemConfig.props.messageConfig.styleType === 'template3'
            "
          >
            <!-- 高度 67（相对 375） -->
            <img
              src="../../assets/images/st-me-page/template3-bg.png"
              style="width: 100%"
            />
          </div>
        </div>
      </div>

      <!-- 页面设置（头部导航） -->
      <component
        v-if="!hasCustomHeader && from !== 'st-custom-nav' && from !== 'st-bottom-nav'"
        :config="page.props"
        :headerJump="
          setting && setting.headerJump ? setting.headerJump.props : {}
        "
        :is="page.component"
      />

      <!-- 系统页 -->
      <component
        :config="page.props"
        :headerJump="
          setting && setting.headerJump ? setting.headerJump.props : {}
        "
        :is="page.component"
        v-if="isSystemPage"
      />

      <!-- 组件列表 -->
      <component
        :config="item.props"
        :index="index"
        :is="item.component"
        :key="item.id"
        :page="page"
        v-for="(item, index) in list"
        class="component-item"
      />

      <!-- 个人中心底部固定样式  -->
      <div class="me-page-bottom" v-if="system_page === 'me_new'">
        <div class="user-outlog">
          <p>清除缓存</p>
          <img
            class="handle-icon"
            src="../../assets/images/st-me-page/handle-next.png"
          />
        </div>
        <div class="user-outlog">
          <p>退出账号</p>
          <img
            class="handle-icon"
            src="../../assets/images/st-me-page/handle-next.png"
          />
        </div>
        <div class="bottom-watermark">
          <div
            :key="index"
            class="mark-item"
            v-for="(item, index) in bottomWatermarkList"
          >
            <el-image
              :src="item.icon"
              class="mark-icon"
              fit="contain"
              v-if="item.type === 'icon'"
            >
            </el-image>
            <p class="mark-content" v-if="item.type === 'content'">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex-align-center" style="min-height: 100px;" v-if="!system_page">
        <st-bottom-watermark :config="setting.bottomWatermark.props" />
      </div>
    </template>
    <template v-else-if="!loading">
      <div class="flex-1 flex-center">
        <el-empty />
      </div>
    </template>
  </div>
</template>

<script>
/**
 * 纯展示的装修组件列表，用于内置页展示
 */

import { getPageDetail } from '../../api/page'
import { getDetail } from '../../../system-admin/api/cyc-content'

import { formatConfigData } from '@/modules/app-design/assets/js/tool'
import { randomString } from '../../../../base/utils/tool'
import previewComponents from '@/modules/app-design/assets/js/view-component'
import { dynamicComponents } from '@/modules/app-design/assets/js/view-component'
// 富文本相关组件
import Tinymce from '@/base/components/Editor/Tinymce'
import DownloadItem from '../../../system-admin/components/download-item/download-item'
import StBottomWatermark from '../Preview/StBottomWatermark'

let library = require('../../assets/jsons/component-library.json')
let { setting, page, system } = JSON.parse(JSON.stringify(library))

// console.log('components', previewComponents)
// let timer = null

// function loadCustomComponents() {
//   if (!previewComponents) {
//     timer = setTimeout(() => {
//       import previewComponents from '@/modules/app-design/assets/js/preview-component'
//       console.log('components', previewComponents)
//       if (previewComponents) {
//         if (timer) {
//           clearTimeout(timer)
//           timer = null
//         }
//       } else {
//         loadCustomComponents()
//       }
//     }, 500)
//   }
// }

export default {
  inject: {
    getBottomWatermarkList: {
      value: () => [],
      default: () => [],
    },
    // ratio: {
    //   value: '',
    //   default: '',
    // },
    getFullscreen: {
      value: null,
      default: () => {},
    },
    setting: {
      value: null,
      default: null,
    },
  },
  components: {
    StBottomWatermark,
    ...previewComponents,
    ...dynamicComponents,
    DownloadItem,
    Tinymce,
  },
  props: {
    id: [String, Number],
    system_page: String,
    jump_link: Object,
    init: Boolean,
    from: String, // 来自自定义导航、底部导航等
  },
  data() {
    return {
      loading: true,
      page: page,
      list: [],

      form: {},

      isH5: this.$route.params.platform === 'h5',
    }
  },
  computed: {
    // 自定义头部
    hasCustomHeader() {
      let bol = false
      let li = this.list
      if (li && li[0] && li[0].component === 'st-custom-header') {
        bol = true
      } else if (
        li.findIndex((l) => l.component === 'st-custom-header') !== -1
      ) {
        bol = true
      }
      return bol
    },
    bottomWatermarkList() {
      return this.getBottomWatermarkList()
    },
    systemConfig() {
      return this.page
    },
    isSystemPage() {
      if (this.id || this.system_page) {
        return !(this.id && !this.system_page)
      }
      const {id, system_page} = this.jump_link.jump_config.view;
      return !(id && !system_page)
    }
  },
  watch: {
    init: {
      handler(val) {
        if (val) {
          if (this.id || this.system_page) {
            this.getPageDetail()
            if (this.from && ['st-bottom-nav', 'st-custom-nav'].includes(this.from)) {
               this.getFullscreen(true)
            } else {
              this.getFullscreen(!this.id && this.system_page) // 将头部导航交给内部 view（PageView） 处理
            }
          } else if (this.isSinglePage(this.jump_link?.jump_config)) {
            this.getCycDetail()
          } else {
            this.loading = false;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCycDetail() {
      this.form.id = this.jump_link.jump_config.path
        .replace('/sub/single/pages/index?id=', '')
        .replace('sub/single/pages/index?id=', '')

      if (this.form.id != 0) {
        this.loading = true

        getDetail({ id: this.form.id })
          .then((res) => {
            this.form = res.data
            this.form.files = res.data.files.map((f) => ({
              ...f,
              name: f.title,
            }))
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    getPageDetail() {
      this.loading = true

      this.postData = {}
      // 赋值postData 并注入子组件
      this.postData.project_id = this.$route.params.id
      this.postData.pageset = this.$route.query.pageset || 'preview'

      getPageDetail({
        ...(!this.system_page
          ? { id: this.id, system_page: '' }
          : { system_page: this.system_page, id: 0 }),
        ...this.postData,
      }, {
        errorToast: this.from === 'st-custom-nav' ? false : true
      })
        .then(({ data }) => {
          const { list, page: _page } = formatConfigData(
            data.config || {},
            data.system_page
          )
          this.page = _page
          this.list = []
          this.list.push(...list.map((li) => ({ ...li, id: randomString(8) })))
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 判断是单页面，可以直接点击去编辑哦
     */
    isSinglePage(config) {
      return config.path.indexOf('sub/single/pages/index?id=') !== -1
    },
  },
}
</script>

<style lang="scss">
.page-view {
  width: 100%;
  min-height: 300px;
  max-width: 375px;

  .free-container {
    position: relative;
  }
}

.st-bottom-nav .page-view {
  min-height: calc(667px - 54px);
}
</style>
