<template>
  <!-- 子组件也可以直接使用，如：color: var(--main-color); -->
  <div
    class="page-middle"
    :style="{
      '--main-color': mainColor,
      '--help-color': helpColor,
      '--text-color': textColor,
    }"
  >
    <!-- 系统设置 -->
    <template v-if="isSystemActive">
      <div class="workspace-middle-box">
        <div
          class="workspace-middle-page no-hover"
          style="background-color: #fff"
        >
          <!-- 头部背景层 -->
          <div
            v-if="page.props && page.props.header && value !== 'share'"
            :class="['drag-item no-hover']"
            :style="{
              height: page.props.header.height + 64 + 'px',
              position: 'absolute',
            }"
          >
            <div
              class="header-fixed"
              :style="{ height: '64px', backgroundColor: 'white' }"
              v-if="
                setting.headerJump &&
                setting.headerJump.props &&
                setting.headerJump.props.showConfig
              "
            >
              <!-- 头部跳转设置 -->
              <div
                :class="[
                  setting.headerJump.props.showConfig.showType !== 'template1'
                    ? 'template-back-icon'
                    : 'default-back-icon',
                ]"
              >
                <div class="back-icon"></div>
                <div
                  v-if="
                    setting.headerJump.props.showConfig.showType ===
                      'template3' && value === 'headerJump'
                  "
                  class="link-dialog1"
                >
                  <div
                    class="link-item1"
                    v-for="(item, index) in setting.headerJump.props.tabs.list
                      .length
                      ? headerJumpList
                      : require('../../assets/jsons/default-list.json')
                          .headerJump"
                    :key="index"
                  >
                    <el-image
                      class="link-image1"
                      :src="
                        item.icon
                          ? fixImageUrl(item.icon, {}, true)
                          : require('../../assets/images/default-icon.png')
                      "
                      fit="cover"
                    >
                    </el-image>
                    <p class="link-name1">
                      {{ item.name ? item.name : `导航${ch_sort[index]}` }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    setting.headerJump.props.showConfig.showType ===
                      'template4' && value === 'headerJump'
                  "
                  class="link-dialog2"
                >
                  <div
                    class="link-item2"
                    v-for="(item, index) in setting.headerJump.props.tabs.list
                      .length
                      ? headerJumpList
                      : require('../../assets/jsons/default-list.json')
                          .headerJump"
                    :key="index"
                  >
                    <el-image
                      class="link-image2"
                      :src="
                        item.icon
                          ? fixImageUrl(item.icon, {}, true)
                          : require('../../assets/images/default-icon.png')
                      "
                      fit="cover"
                    >
                    </el-image>
                    <p class="link-name2">
                      {{ item.name ? item.name : `导航${ch_sort[index]}` }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="workspace-middle-page-content">
            <!-- 默认头部导航 -->
            <div
              v-if="
                !fullscreen &&
                value !== 'theme' &&
                value !== 'share' &&
                value !== 'eleCard'
              "
              :class="['drag-item no-hover']"
            >
              <st-page-set
                :title="setting[value].title || setting[value].name"
                :jump-config="
                  setting && setting.headerJump ? setting.headerJump.props : {}
                "
              />
            </div>
            <draggable
              v-model="myList"
              class="draggable-box"
              :class="[
                value === 'bottomNav' || value === 'headerJump' ? 'system' : '',
              ]"
              :style="
                value === 'share' ||
                value === 'posterShare' ||
                value === 'theme'
                  ? 'padding-bottom: 0px !important'
                  : ''
              "
              draggable=".drag-item"
              animation="400"
              ghostClass="ghost"
              :options="listOptions"
              :move="onMove"
              filter=".no-drag"
              @add="handleAdd"
            >
            </draggable>
            <div v-if="value === 'theme'" class="drag-item no-hover theme">
              <st-theme :config="setting[value].props"></st-theme>
            </div>
            <div v-if="value === 'share'" class="drag-item no-hover share">
              <st-share :config="setting[value].props"></st-share>
            </div>
            <div
              v-if="value === 'bottomWatermark'"
              class="drag-item no-hover bottomWatermark"
            >
              <st-bottom-watermark
                :config="setting[value].props"
              ></st-bottom-watermark>
            </div>
            <div v-if="value === 'posterShare'" class="drag-item no-hover">
              <st-poster-share :config="setting[value].props"></st-poster-share>
            </div>
            <div v-if="value === 'eleCard'" class="drag-item no-hover">
              <st-electron-card
                :config="setting[value].props"
              ></st-electron-card>
            </div>
            <div v-if="value === 'levels'" class="drag-item no-hover">
              <st-electron-card
                :config="setting[value].props"
              ></st-electron-card>
            </div>
            <div
              v-else-if="value === 'internet_content_provider'"
              class="drag-item no-hover"
            >
              <st-internet-content-provider :config="setting[value].props" />
            </div>
          </div>
          <!-- 底部导航 -->
          <div
            v-if="
              ['internet_content_provider'].includes(value) ||
              value === 'bottomNav'
            "
            :style="{
              outline:
                (['internet_content_provider'].includes(value) ||
                  value === 'bottomNav') &&
                system['bottomNav'].props.templateData.styleType === 'template1'
                  ? ''
                  : 'none',
            }"
            class="drag-item fixed no-hover"
          >
            <st-bottom-nav :config="system['bottomNav'].props" />
          </div>
        </div>
      </div>
    </template>
    <!-- 自定义页面 -->
    <template v-else-if="pageDetail && pageDetail.id">
      <div class="workspace-middle-box" @click="updateSelected('page')">
        <!--
        背景色，叠加一个全局默认底色，防止用户将背景色设为透明
        background-image 叠加规则：
        1、使用逗号分隔每个背景层。
        2、在绘制时，图像以 z 方向堆叠的方式进行。先指定的图像会在之后指定的图像上面绘制。因此指定的第一个图像“最接近用户”。
        -->

        <div
          class="workspace-middle-page"
          id="capture"
          :style="{
            transform: `scale(${ratio})`,
            backgroundImage:
              (topBannerCurrent && `url(${topBannerCurrent})`) ||
              page.props.normal.bgType === 'image'
                ? `url(${
                    page.props.normal.bgImage
                  }),linear-gradient(to bottom, ${
                    page.props.normal.bgType === 'color'
                      ? page.props.normal.bgColor
                      : 'transparent'
                  }, ${
                    page.props.normal.bgType === 'color'
                      ? page.props.normal.bgColor
                      : 'transparent'
                  }), linear-gradient(to bottom, #f8f8f8, #f8f8f8)`
                : `linear-gradient(to bottom, ${
                    page.props.normal.bgType === 'color'
                      ? page.props.normal.bgColor
                      : 'transparent'
                  }, ${
                    page.props.normal.bgType === 'color'
                      ? page.props.normal.bgColor
                      : 'transparent'
                  }), linear-gradient(to bottom, #f8f8f8, #f8f8f8)`,
            backgroundRepeat:
              topBannerCurrent || page.props.normal.bgType === 'image'
                ? 'no-repeat'
                : '',
            backgroundSize: topBannerCurrent ? '100%' : '',
          }"
        >
          <!-- 头部背景层 -->
          <!--
 &&(!isH5 || (isH5 && hasCustomHeader))
-->
          <div
            class="page-top"
            :class="['drag-item no-hover']"
            v-if="page.props && page.props.header"
            :style="{
              height: page.props.header.height + (isH5 ? 0 : 64) + 'px',
              top: isH5 ? '64px' : 0,
            }"
            style="position: absolute"
          >
            <div
              class="header-fixed"
              :style="{
                height: 'inherit',
                overflow: 'hidden',
              }"
            >
              <!-- 背景层 -->
              <div
                class="page-bg"
                :style="{
                  height: 'inherit',
                  backgroundColor:
                    page.props.header.bgType === 'image'
                      ? ''
                      : page.props.header.bgColor || '',
                  borderRadius:
                    page.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    page.props.header.radian > 0
                      ? 200 - page.props.header.radian * 1 + '%'
                      : '100%',
                  marginLeft:
                    page.props.header.radian > 0
                      ? -(200 - page.props.header.radian * 1) / 2 + '%'
                      : '-50%',
                  opacity: 1 - page.props.header.opacity / 100,
                }"
              >
                <el-image
                  :style="{
                    height: 'fit-content' || 'inherit',
                  }"
                  fit="contain"
                  v-if="page.props.header.bgType === 'image'"
                  :src="page.props.header.bgImage"
                />
              </div>
            </div>
          </div>
          <!-- 顶部栏模板，模板七 -->
          <div
            v-if="
              hasCustomHeader &&
              list[0] &&
              list[0].props &&
              list[0].props.templateData &&
              list[0].props.templateData.template === 'template7'
            "
            :style="{
              height: `${
                20 +
                44 +
                list[0].props.contentConfigTemplate7.coverHeight +
                167 +
                list[0].props.contentConfigTemplate7.colorHeight +
                105
              }px`,
              // 写在最后面的在最底层
              backgroundImage: `
        linear-gradient(to bottom, ${
          list[0].props.contentConfigTemplate7.color
        }00 ${
                ((20 + 44 + list[0].props.contentConfigTemplate7.coverHeight) *
                  100) /
                (20 +
                  44 +
                  list[0].props.contentConfigTemplate7.coverHeight +
                  167 +
                  list[0].props.contentConfigTemplate7.colorHeight +
                  105)
              }%, ${list[0].props.contentConfigTemplate7.color} ${
                ((20 +
                  44 +
                  list[0].props.contentConfigTemplate7.coverHeight +
                  167) *
                  100) /
                (20 +
                  44 +
                  list[0].props.contentConfigTemplate7.coverHeight +
                  167 +
                  list[0].props.contentConfigTemplate7.colorHeight +
                  105)
              }%, ${list[0].props.contentConfigTemplate7.color} 82.87%, ${
                list[0].props.contentConfigTemplate7.color
              }00 100%),
        url(${list[0].props.contentConfigTemplate7.cover})`,
            }"
            class="header-page-top"
            style="
              width: 100%;
              top: 0;
              position: absolute;
              background-size: 100% auto;
              background-position: center top;
              background-repeat: no-repeat;
            "
          ></div>
          <div
            class="workspace-middle-page-content"
            :style="{
              paddingBottom: isTabPage
                ? `${
                    (system['bottomNav'].props.templateData.styleType ===
                    'template1'
                      ? 50
                      : 0) + page.props.normal.paddingBottom
                  }px`
                : page.props.normal.paddingBottom + 'px',
            }"
          >
            <!-- 默认头部导航 -->
            <div
              v-if="!fullscreen && page && (!hasCustomHeader || isH5)"
              class="header"
              :class="['drag-item no-hover']"
            >
              <st-page-set
                :config="page.props"
                :jump-config="
                  setting && setting.headerJump ? setting.headerJump.props : {}
                "
              />
            </div>
            <!-- 页面侧边悬浮按钮 -->
            <div
              class="aside-fixed"
              :class="[page.props.serviceBtn.showPosition]"
              :style="{
                bottom:
                  hasCommunityNav &&
                  communityNavConfig.props.issueConfig.issuePost
                    ? '243px'
                    : page.props.serviceBtn.fixBottom + 'px',
              }"
              v-if="
                page.props.serviceBtn &&
                page.props.serviceBtn.isShow &&
                page.props.serviceBtn.payStatus
              "
            >
              <el-image
                class="aside-icon"
                :src="
                  require('../../assets/images/middle/customer-service.png')
                "
              >
              </el-image>
            </div>
            <!-- 咨询按钮 -->
            <div
              class="aside-fixed consult"
              :class="[page.props.consultBtn.showPosition]"
              :style="{
                bottom:
                  hasCommunityNav &&
                  communityNavConfig.props.issueConfig.issuePost
                    ? '243px'
                    : page.props.consultBtn.fixBottom + 'px',
              }"
              v-if="
                isFeatureConsult &&
                page.props.consultBtn &&
                page.props.consultBtn.isShow &&
                page.props.consultBtn.payStatus
              "
            >
              <el-image
                class="aside-icon"
                :src="
                  require('../../assets/images/middle/customer-service-icon.png')
                "
              >
              </el-image>
            </div>

            <!-- 申请入会底部导航 template-2 -->
            <!-- v-if="isTabPage"  -->
            <div
              v-if="
                joinFloat &&
                page.props.requestJoin.template === 'template2' &&
                page.props.requestJoin.isShow === 1
              "
              style="
                width: 100%;
                position: absolute;
                bottom: 0;
                padding: 8px;
                min-height: 48px;
                background: #fff;
                z-index: 3;
                border-radius: 16px 16px 0 0;
                padding-top: 8px;
                padding-bottom: 12px;
                display: flex;
                align-items: center;
                box-shadow: 0px -12px 32px rgba(0, 0, 0, 0.08);
              "
              :style="{
                bottom: hasBottomNavComponent
                  ? '50px'
                  : isTabPage
                  ? '50px'
                  : '0px',
              }"
            >
              <div style="margin-left: 8px; margin-right: 12px">
                <img style="width: 52px; height: 52px" :src="peopleData.icon" />
              </div>
              <div
                style="
                  flex: 1;
                  color: #1a1a1a;
                  font-size: 14px;
                  text-align: center;
                "
              >
                <div style="display: flex; align-items: center">
                  <img
                    style="width: 24px; margin-right: 8px"
                    :src="peopleData.project_icon"
                  />
                  <div
                    style="
                      text-align: left;
                      color: #1a1a1a;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 32px;
                    "
                  >
                    {{ peopleData.auth_applet_name }}
                  </div>
                </div>
                <div
                  style="display: flex; align-items: center"
                  v-if="peopleData.count !== 0"
                >
                  <div
                    style="
                      background-size: 100% 100%;
                      width: 18px;
                      height: 18px;
                      border-radius: 24px;
                      border: 1px solid #fff;
                      margin-right: -6px;
                    "
                    :style="{
                      backgroundImage: `url(${
                        user.avatar || peopleData.default_avatar
                      })`,
                    }"
                    v-for="user in peopleData.list.slice(0, 3)"
                  ></div>
                  <span
                    style="text-indent: 12px; color: #808080; font-size: 12px"
                    >已有 {{ peopleData.count }} 人入会</span
                  >
                </div>
              </div>
              <div
                style="
                  margin-left: 8px;
                  height: 100%;
                  color: #fff;
                  padding: 12px;
                  padding-top: 6px;
                  padding-bottom: 6px;
                  border-radius: 32px;
                  margin-right: 8px;
                  font-size: 14px;
                "
                :style="{
                  background:
                    page.props.requestJoin.bgType === 'theme'
                      ? 'var(--main-color)'
                      : page.props.requestJoin.bgColor,
                }"
              >
                申请入会
              </div>
            </div>
            <!-- 申请入会 模板1 -->
            <div
              v-if="
                joinFloat &&
                page.props.requestJoin.template === 'template1' &&
                page.props.requestJoin.isShow === 1
              "
              style="
                width: calc(100% - 42px);
                left: 21px;
                min-height: 48px;
                position: absolute;
                background: #fff;
                z-index: 4;
                border-radius: 32px;
                bottom: 0;
                margin-top: 16px;
                margin-bottom: 16px;
                box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.08);
                display: flex;
                align-items: center;
              "
              :style="{
                bottom: isTabPage ? '50px' : '0px',
                bottom: hasBottomNavComponent
                  ? '50px'
                  : isTabPage
                  ? '50px'
                  : '0px',
              }"
            >
              <div style="margin-left: 8px; margin-right: 8px">
                <img
                  style="width: 38px; height: 38px"
                  src="../../assets/images/vip.png"
                />
              </div>
              <div
                style="
                  flex: 1;
                  color: #1a1a1a;
                  font-size: 14px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                "
              >
                <div
                  style="display: flex; align-items: center; margin-right: 20px"
                  v-if="peopleData.count > 0"
                >
                  <div
                    style="
                      background-size: 100% 100%;
                      width: 24px;
                      height: 24px;
                      border-radius: 24px;
                      border: 1px solid #fff;
                      margin-right: -12px;
                    "
                    :style="{
                      backgroundImage: `url(${
                        user.avatar || peopleData.default_avatar
                      })`,
                    }"
                    v-for="user in peopleData.list.slice(0, 3)"
                  ></div>
                </div>
                <div v-if="peopleData.count > 0">
                  已有 {{ peopleData.count }} 人入会
                </div>
                <img
                  v-if="peopleData.count === 0"
                  style="width: 24px; margin-right: 8px"
                  :src="peopleData.project_icon"
                />
                <div v-if="peopleData.count === 0">
                  {{ peopleData.auth_applet_name }}
                </div>
              </div>
              <div
                style="
                  margin-left: 8px;
                  height: 100%;
                  color: #fff;
                  padding: 12px;
                  padding-top: 6px;
                  padding-bottom: 6px;
                  border-radius: 32px;
                  margin-right: 8px;
                  font-size: 14px;
                  margin-top: 12px;
                  margin-bottom: 12px;
                "
                :style="{
                  background:
                    page.props.requestJoin.bgType === 'theme'
                      ? 'var(--main-color)'
                      : page.props.requestJoin.bgColor,
                }"
              >
                申请入会
              </div>
            </div>

            <!-- 自定义页面缺省页 -->
            <div class="none-list" v-if="myList.length === 0">
              <div class="none-list-content">
                <img
                  class="none-list-img"
                  src="../../assets/images/custom-page-none.png"
                />
                <p class="none-list-tip">
                  添加组件，设计您的专属{{ isH5 ? 'H5 微网站' : '小程序' }}
                </p>
                <el-button
                  style="margin-top: 27px"
                  type="primary"
                  @click="handleAddComp"
                >
                  添加组件</el-button
                >
              </div>
            </div>

            <!-- 自定义头部 -->
            <div
              v-if="hasCustomHeader"
              class="drag-item no-drag"
              :class="value === 0 ? 'active' : ''"
              @click.stop="updateSelected(0)"
              :id="myList[0].extra.timestamp"
            >
              <component
                is="st-custom-header"
                :config="myList[0].props"
                :version="myList[0].version"
                :page="page"
              ></component>
              <page-middle-item-tool
                :title="myList[0].title"
                @delete="deletePageItem(0)"
              />
            </div>

            <!-- 其他组件 页面设置两侧边距：padding: `0 ${page.props.normal.rowPadding}px`-->
            <draggable
              v-model="myList"
              class="draggable-box"
              :style="{
                paddingBottom: isH5 ? 0 : '2px',
                minHeight:
                  !hasCustomNav && !hasCustomHeader && !hasCommunityNav
                    ? isH5
                      ? '603px'
                      : '553px'
                    : hasCustomHeader && !hasCustomNav && !hasCommunityNav
                    ? '491px'
                    : '1px',
              }"
              filter=".forbid"
              animation="400"
              ghostClass="ghost"
              :options="listOptions"
              :move="onMove"
              :disabled="!pageDetail.id"
              @sort="handleSort"
              @add="handleAdd"
            >
              <div
                :class="[
                  'drag-item',
                  li.extra.version_different
                    ? 'is-different'
                    : value === i
                    ? 'active'
                    : '',
                  li.component,
                  havePageLoadingComponent.includes(li.component) &&
                  li.props.loadingConfig.loadingType === 'page'
                    ? 'forbid'
                    : '',
                ]"
                @click.stop="updateSelected(i)"
                v-for="(li, i) in myList"
                v-if="
                  li &&
                  li.component &&
                  ![
                    'st-custom-header',
                    'st-custom-nav',
                    'st-community-nav',
                    'st-membership-dynamics',
                    'st-community-square',
                    'st-community-nav-old',
                    'st-article-comment-tab',
                  ].includes(li.component)
                "
                :key="li.extra.timestamp"
                :id="li.extra.timestamp"
              >
                <!-- 支持拉伸组件高度的代码 -->
                <!-- :styles="li.props.styles" :config="li.props.config" :resize="main.hover === i"-->
                <!--                             @updateTextHeight="li.props.styles.height = $event"-->
                <!--                             :resize="value === i"-->
                <component
                  ref="dynamicCom"
                  :is="li.component"
                  :config="li.props"
                  :version="li.version"
                  :index="i"
                  :is-active="i === value"
                  :jump-config="
                    setting && setting.headerJump
                      ? setting.headerJump.props
                      : {}
                  "
                >
                  <!-- 自定义页面缺省页 -->
                  <div
                    class="none-list"
                    v-if="false && ['st-bottom-nav'].includes(li.component)"
                  >
                    <div class="none-list-content">
                      <img
                        class="none-list-img"
                        src="../../assets/images/custom-page-none.png"
                      />
                      <template v-if="li.component === 'st-bottom-nav'">
                        <p class="none-list-tip">
                          该组件为底部导航设置，无法再添加其他组件
                        </p>
                      </template>
                    </div>
                  </div>
                </component>
                <div
                  class="different-version-tip"
                  v-if="li.extra.version_different"
                >
                  组件版本已更新，请留意设置内容
                </div>
                <page-middle-item-tool
                  :title="li.title"
                  @delete="deletePageItem(i)"
                ></page-middle-item-tool>
              </div>
            </draggable>
            <div
              v-if="joinFloat && page.props.requestJoin.isShow === 1"
              :style="{
                height: hasBottomNavComponent || hasCustomNav
                  ? '0px'
                  : page.props.requestJoin.template === 'template1'
                  ? '96px'
                  : '96px',
              }"
            ></div>
            <!-- 自定义导航 -->
            <div
              v-if="hasCustomNav || hasCommunityNav"
              class="drag-item no-drag"
              :class="value === myList.length - 1 ? 'active' : ''"
              :style="{
                width: '100%',
              }"
              :id="myList[myList.length - 1].extra.timestamp"
              @click.stop="updateSelected(myList.length - 1)"
            >
              <component
                :is="myList[myList.length - 1].component"
                :config="myList[myList.length - 1].props"
              />
              <page-middle-item-tool
                :title="myList[myList.length - 1].title"
                @delete="deletePageItem(myList.length - 1)"
              />
            </div>

            <!-- 底部水印 -->
            <div class="flex-center" style="min-height: 100px;" v-if="myList.length > 0">
              <st-bottom-watermark :config="setting.bottomWatermark.props" />
            </div>

            <!-- 底部导航 -->
            <div
              v-if="isTabPage"
              :class="[
                'drag-item no-hover no-drag fixed',
                value === 'bottomNav' ? 'active' : '',
              ]"
              :style="{
                outline:
                  system['bottomNav'].props.templateData.styleType ===
                  'template1'
                    ? ''
                    : 'none',
              }"
              @click.stop="updateSelected('bottomNav')"
            >
              <div style="position: relative; z-index: 3">
                <st-bottom-nav
                  :config="system['bottomNav'].props"
                  :value="bottomActive"
                />
                <page-middle-item-tool
                  v-if="
                    system['bottomNav'].props.templateData.styleType ===
                    'template1'
                  "
                  :hide-delete="true"
                  :title="system['bottomNav'].title"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 右上角按钮组 -->
      <div class="workspace-middle-buttons" v-if="pageDetail.id">
        <el-button
          v-if="pageDetail.id"
          :type="value === 'page' ? 'primary' : ''"
          @click.stop="updateSelected('page')"
        >
          <span class="middle-button-content">
            <img
              v-if="value === 'page'"
              src="../../assets/images/middle/page-setting-white.png"
            />
            <img
              v-else
              src="../../assets/images/middle/page-setting.png"
            /><span class="button-text">页面设置</span></span
          >
        </el-button>
        <!-- 当前页面未关联系统底部导航且页面无底部导航组件方可添加导航，超过五个也允许用户点击查看详情 -->
        <el-button
          v-if="pageDetail && !hasTabPage && !hasBottomNavComponent"
          :type="value === 'bottomNav' ? 'primary' : ''"
          @click.stop="addBottomNav"
        >
          <span class="middle-button-content">
            <img
              v-if="value === 'bottomNav'"
              src="../../assets/images/middle/add-nav-white.png"
            />
            <img v-else src="../../assets/images/middle/add-nav.png" /><span
              class="button-text"
              >添加导航</span
            ></span
          >
        </el-button>
        <!--        <el-button v-if="value === 'page'" @click.stop="captureCurrentPage" :disabled="captureLoading">-->
        <!--          <div class="middle-button-content">-->
        <!--            <i class="el-icon" :class="captureLoading?'el-icon-loading':'el-icon-camera'" />-->
        <!--            <span class="button-text">生成截图</span>-->
        <!--          </div>-->
        <!--        </el-button>-->
        <!--        <el-button v-if="myList.length" :type="value === 'list' ? 'primary' : ''" @click.stop="updateSelected('list')">-->
        <!--          <span class="middle-button-content"><img src="../../assets/images/settings.png" />组件列表</span>-->
        <!--        </el-button>-->
        <el-button-group class="scale-control">
          <el-button
              @click="ratio -= 0.05"
              icon="el-icon-minus"
              type=""
          ></el-button>
          <el-button
              @click="ratio = 1"
              icon="el-icon-c-scale-to-original"
              type=""
          ></el-button>
          <el-button
              @click="ratio += 0.05"
              icon="el-icon-plus"
              type=""
          ></el-button>
        </el-button-group>
      </div>
    </template>
    <!-- 所有系统页面在这里显示 -->
    <template v-else-if="pageDetail && pageDetail.system_page">
      <!-- 系统页的标题在各自组件中实现，可以使用封装好的 <st-page-set> 标题组件，兼容小程序和 H5 -->
      <div class="workspace-middle-box" @click="updateSelected()">
        <!-- ！有坑，这里背景默认 repeat-y -->
        <div
          class="workspace-middle-page system-page"
          :class="[pageDetail.system_page]"
          :style="{
            transform: `scale(${ratio})`,
            backgroundImage:
              systemConfig &&
              systemConfig.props &&
              systemConfig.props.normal &&
              systemConfig.props.normal.bgType === 'image' &&
              !['contactUs'].includes(pageDetail.system_page)
                ? `url(${systemConfig.props.normal.bgImage})`
                : '',
            backgroundColor:
              (systemConfig &&
                systemConfig.props &&
                (systemConfig.props.normal &&
                systemConfig.props.normal.bgType === 'color'
                  ? systemConfig.props.normal.bgColor
                  : '')) ||
              (['me_new', 'memberIndex', 'contactUs'].includes(
                pageDetail.system_page
              )
                ? '#F4F5F6'
                : '#fff'),
          }"
        >
          <!-- 头部背景层 -->
          <div
            class="header-box"
            :class="['drag-item no-hover']"
            v-if="
              systemConfig && systemConfig.props && systemConfig.props.header
            "
            :style="{
              height:
                systemConfig.props.header.height +
                (isH5
                  ? ['st-analysis-page', 'st-analysis-page-for-user'].includes(
                      systemConfig.component
                    )
                    ? 0
                    : 44
                  : 64) +
                'px',
              top: isH5 ? '64px' : 0,
            }"
            style="position: absolute"
          >
            <div
              class="header-fixed"
              :style="{
                height: '100%',
                overflow: 'hidden',
              }"
            >
              <!-- 背景层 -->
              <div
                class="page-bg"
                :style="{
                  height:
                    ['analysis', 'analysis_user'].includes(
                      pageDetail.system_page
                    ) &&
                    systemConfig &&
                    systemConfig.props &&
                    systemConfig.props.normal &&
                    systemConfig.props.normal.bgType === 'image' &&
                    systemConfig.props.normal.bgImage
                      ? 0
                      : systemConfig.props.header.height + 64 + 'px',
                  backgroundColor:
                    systemConfig.props.header.bgType === 'image'
                      ? ''
                      : systemConfig.props.header.bgColor || '',
                  borderRadius:
                    systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    systemConfig.props.header.radian > 0
                      ? 200 - systemConfig.props.header.radian * 1 + '%'
                      : '100%',
                  marginLeft:
                    systemConfig.props.header.radian > 0
                      ? -(200 - systemConfig.props.header.radian * 1) / 2 + '%'
                      : '-50%',
                  opacity: 1 - systemConfig.props.header.opacity / 100,
                }"
              >
                <el-image
                  fit="cover"
                  :style="{
                    height: systemConfig.props.header.height + 64 + 'px',
                  }"
                  v-if="systemConfig.props.header.bgType === 'image'"
                  :src="systemConfig.props.header.bgImage"
                />
              </div>
            </div>
          </div>
          <div
            class="workspace-middle-page-content"
            :style="{
              backgroundColor: systemPageBgColor,
              paddingBottom:
                systemConfig &&
                systemConfig.props &&
                systemConfig.props.normal &&
                systemConfig.props.normal.paddingBottom !== undefined
                  ? `${getPaddingBottomHeight(
                      systemConfig.props.normal.paddingBottom
                    )}px`
                  : `${getPaddingBottomHeight(0)}px`,
            }"
          >
            <!-- 个人中心头部样式 -->
            <div
              v-if="pageDetail.system_page === 'me_new' && !hasBottomNavComponent"
              class="me-bg-content"
              :style="{
                height:
                  (isH5 ? 135 : 135) + systemConfig.props.header.height + 'px',
                marginTop: isH5 ? '64px' : 0,
              }"
            >
              <div
                class="me-bg"
                :style="{
                  backgroundColor:
                    systemConfig.props.header.bgType === 'color'
                      ? systemConfig.props.header.bgColor
                      : '',
                  borderRadius:
                    systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    systemConfig.props.header.radian > 0
                      ? 300 - systemConfig.props.header.radian * 2 + '%'
                      : '100%',
                  marginLeft:
                    systemConfig.props.header.radian > 0
                      ? -(300 - systemConfig.props.header.radian * 2) / 2 + '%'
                      : '-50%',
                  opacity: 1 - systemConfig.props.header.opacity / 100,
                }"
              >
                <div
                  v-if="systemConfig.props.header.bgType === 'image'"
                  class="me-bg-image-box"
                  :style="{
                    backgroundImage: `url(${
                      systemConfig.props.header.bgImage ||
                      baseUrl + '/cyc/images/wxapps/images/thumb_up.png'
                    })`,
                  }"
                ></div>
                <!-- 不给它加遮罩层了，图片自己实现渐变吧 -->
                <div
                  class="template3-bg flex"
                  v-if="
                    false &&
                    systemConfig.props.messageConfig.styleType === 'template3'
                  "
                  style="position: absolute; width: 375px"
                  :style="{
                    top: !isH5
                      ? `${
                          // systemConfig.props.header.height + 80}px`
                          systemConfig.props.header.height + 135 - 67
                        }px`
                      : `${systemConfig.props.header.height + 135 - 67}px`,
                  }"
                >
                  <!-- 高度 67（相对 375） -->
                  <img
                    style="width: 100%"
                    src="../../assets/images/st-me-page/template3-bg.png"
                  />
                </div>
              </div>
            </div>
            <!-- 单页面配置系统页 -->
            <template v-if="pageDetail.config.page && systemConfig">
              <!--<st-page-set
                v-if="pageDetail.system_page === 'index'"
                title="底部导航"
              />-->
              <component
                :is="systemConfig.component"
                :component-name="systemConfig.component"
                :config="systemConfig.props"
                :jump-config="
                  setting && setting.headerJump ? setting.headerJump.props : {}
                "
                :headerJump="
                  setting && setting.headerJump ? setting.headerJump.props : {}
                "
                :bottomWatermark="setting.bottomWatermark.props"
                :index="0"
                :firstCompConfig="myList[0] ? myList[0].props : {}"
              />
              <!-- 页面侧边悬浮按钮 -->
              <template
                v-if="
                  systemConfig.props.serviceBtn &&
                  systemConfig.props.serviceBtn.isShow &&
                  systemConfig.props.serviceBtn.payStatus
                "
              >
                <div
                  class="aside-fixed"
                  :class="[systemConfig.props.serviceBtn.showPosition]"
                  :style="{
                    bottom:
                      hasCommunityNav &&
                      communityNavConfig.props.issueConfig.issuePost
                        ? '243px'
                        : systemConfig.props.serviceBtn.fixBottom + 'px',
                  }"
                >
                  <el-image
                    class="aside-icon"
                    :src="
                      require('../../assets/images/middle/customer-service.png')
                    "
                  />
                </div>
              </template>
              <template
                v-if="
                  isFeatureConsult &&
                  systemConfig.props.consultBtn &&
                  systemConfig.props.consultBtn.isShow &&
                  systemConfig.props.consultBtn.payStatus
                "
              >
                <div
                  class="aside-fixed consult"
                  :class="[systemConfig.props.consultBtn.showPosition]"
                  :style="{
                    bottom:
                      hasCommunityNav &&
                      communityNavConfig.props.issueConfig.issuePost
                        ? '243px'
                        : systemConfig.props.consultBtn.fixBottom + 'px',
                  }"
                >
                  <el-image
                    class="aside-icon"
                    :src="
                      require('../../assets/images/middle/customer-service-icon.png')
                    "
                  />
                </div>
              </template>
            </template>
            <!-- 多组件系统页 -->
            <!-- 可能对其他系统页有影响哦，删除的代码： && pageDetail.config.list.length -->
            <template v-if="pageDetail.system_page && myList && myList.length">
              <!-- 通用头部样式 -->
              <!-- <div v-if="!hasCustomHeader" class="page-header">
                <img class="start-top-img" src="../../assets/images/start-top.png">
                <div class="page-title">{{pageDetail.name}}</div>
              </div> -->
              <!-- 自定义头部 -->
              <div
                v-if="hasCustomHeader"
                :class="['drag-item', 'no-drag', value === 0 ? 'active' : '']"
                @click.stop="updateSelected(0)"
                :id="myList[0].extra.timestamp"
              >
                <component
                  is="st-custom-header"
                  :config="myList[0].props"
                  :page="page"
                ></component>

                <page-middle-item-tool
                  :title="myList[0].title"
                  @delete="deletePageItem(0)"
                />
              </div>
              <!-- 其他组件 -->
              <draggable
                v-if="!['splash'].includes(pageDetail.system_page)"
                v-model="myList"
                class="draggable-box"
                :style="{
                  minHeight:
                    (['analysis', 'analysis_user'].includes(
                      pageDetail.system_page
                    )
                      ? 'calc(667px - 60px - 64px - 12px - 8px)'
                      : '') ||
                    (['activity_detail', 'analysis'].includes(
                      pageDetail.system_page
                    ) && myList.length === 0
                      ? '200px'
                      : ''),
                }"
                filter=".forbid"
                animation="400"
                ghostClass="ghost"
                :options="listOptions"
                :move="onMove"
                @sort="handleSort"
                @add="handleAdd"
              >
                <div
                  :data-last="
                    li.props.normal && li.props.normal.marginTop
                      ? li.props.normal.marginTop > 0
                      : false
                  "
                  :class="[
                    'drag-item',
                    li.extra.version_different
                      ? 'is-different'
                      : value === i
                      ? 'active'
                      : '',
                    [
                      'st-member-message',
                      'st-new-trends',
                      'st-honor-certificate',
                      'st-about-me',
                      'st-broadcast',
                      'st-related-company',
                    ].includes(li.component)
                      ? 'st-member-index'
                      : li.component,
                    li.is_system ? 'forbid' : '',
                  ]"
                  @click.stop="updateSelected(i)"
                  v-for="(li, i) in myList"
                  v-if="
                    li &&
                    li.component &&
                    ![
                      'st-custom-header',
                      'st-custom-nav',
                      'st-community-nav',
                      'st-community-square',
                      'st-article-comment-tab',
                    ].includes(li.component)
                  "
                  :key="li.extra.timestamp"
                  :id="li.extra.timestamp"
                >
                  <!-- 支持拉伸组件高度的代码 -->
                  <!-- :styles="li.props.styles" :config="li.props.config" :resize="main.hover === i"-->
                  <!--                             @updateTextHeight="li.props.styles.height = $event"-->
                  <!--                             :resize="value === i"-->

                  <component
                    :is="li.component"
                    :component="li.component"
                    :config="li.props"
                    :index="i"
                    :jump-config="
                      setting && setting.headerJump
                        ? setting.headerJump.props
                        : {}
                    "
                    :page="systemConfig.props"
                    :rules-arr="rulesArr"
                  />

                  <div
                    class="different-version-tip"
                    v-if="li.extra.version_different"
                  >
                    组件版本已更新，请留意设置内容
                  </div>
                  <page-middle-item-tool
                    :hide-delete="!!li.is_system"
                    :title="li.title"
                    @delete="deletePageItem(i)"
                  />
                </div>
              </draggable>
              <!-- 系统页底部水印 -->
              <st-bottom-watermark
                style="margin-top: 40px"
                v-if="
                  ['contactUs', 'activity_detail'].includes(
                    pageDetail.system_page
                  ) || pageDetail.system_page === 'contactUs'
                "
                :config="setting.bottomWatermark.props"
              />
              <!-- 活动详情 -->
              <div
                v-if="['activity_detail'].includes(pageDetail.system_page)"
                class="activity-bottom"
              >
                <img
                  class="activity-bottom-img"
                  :src="
                    require('../../assets/images/st-activity-detail/bottom-bar.png')
                  "
                />
              </div>
              <!-- 评论、转发、收藏、点赞区域 -->
              <div
                v-if="articleCommentTab"
                :class="[
                  'drag-item no-drag',
                  'article-commemt',
                  value === myList.length - 1 ? 'active' : '',
                ]"
                :id="articleCommentTab.extra.timestamp"
                @click.stop="updateSelected(myList.length - 1)"
                :style="{
                  backgroundColor: articleCommentTab.props.normal.bgColor,
                  flexDirection:
                    articleCommentTab.props.templateData.styleType ===
                    'template1'
                      ? 'row'
                      : 'row-reverse',
                }"
              >
                <page-middle-item-tool
                  :hide-delete="true"
                  :title="articleCommentTab.title"
                />
                <div
                  class="handle-left"
                  :style="{
                    color:
                      articleCommentTab.props.templateData.placeholderColor,
                    backgroundColor:
                      articleCommentTab.props.templateData.searchColor,
                    borderRadius:
                      articleCommentTab.props.templateData.searchType ===
                      'circle'
                        ? '15px'
                        : '0',
                    justifyContent:
                      articleCommentTab.props.templateData.textPosition ===
                      'center'
                        ? 'center'
                        : '',
                  }"
                >
                  <div class="left-content">
                    <p class="el-icon-edit input-icon"></p>
                    <p class="input-tips">
                      {{ articleCommentTab.props.templateData.placeholder }}
                    </p>
                  </div>
                </div>
                <div class="handle-right">
                  <div class="handle-item">
                    <img
                      class="handle-icon"
                      src="../../assets/images/st-article-detail/liked.png"
                    />
                    <p class="handle-name">
                      {{
                        articleCommentTab.props.templateData.btnType === 'text'
                          ? '点赞'
                          : '999'
                      }}
                    </p>
                  </div>
                  <div class="handle-item">
                    <img
                      class="handle-icon"
                      src="../../assets/images/st-article-detail/star.png"
                    />
                    <p class="handle-name">
                      {{
                        articleCommentTab.props.templateData.btnType === 'text'
                          ? '收藏'
                          : '999'
                      }}
                    </p>
                  </div>
                  <div class="handle-item">
                    <img
                      class="handle-icon"
                      src="../../assets/images/st-article-detail/forward.png"
                    />
                    <p class="handle-name">
                      {{
                        articleCommentTab.props.templateData.btnType === 'text'
                          ? '转发'
                          : '999'
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- 信息反馈底部按钮模块 -->
              <div
                class="submit-btn"
                :style="{
                  boxShadow:
                    systemConfig.props.buttonConfig.interactiveType === 'fixed'
                      ? `0px -2px 4px 0px rgba(238, 238, 238, 0.5)`
                      : '',
                }"
                v-if="pageDetail.system_page === 'ask' && systemConfig"
              >
                <div class="submit-text">
                  {{ systemConfig.props.buttonConfig.btnText }}
                </div>
              </div>
              <!-- 商城主页悬浮购物车 -->
              <div
                class="cart-ball"
                v-if="
                  pageDetail.system_page === 'shop_index' ||
                  pageDetail.system_page === 'integral_shop_index'
                "
              >
                <ColorCustomIcon
                  :width="24"
                  :height="24"
                  :icon="
                    require('../../assets/images/st-shop-goods/button-cart.png')
                  "
                ></ColorCustomIcon>
              </div>
              <!-- 会员主页底部按钮模块 -->
              <div
                class="member-index-bottom"
                v-if="
                  pageDetail.system_page === 'memberIndex' &&
                  !hiddenMemberIndexBtn
                "
              >
                <div
                  class="bottom-left"
                  v-if="systemConfig.props.shareConfig.showShare"
                >
                  <div
                    class="btn-item"
                    v-if="systemConfig.props.shareConfig.showShare"
                  >
                    <img
                      class="item-icon"
                      src="../../assets/images/st-member-index/share-icon2.png"
                    />
                    <div class="item-text">分享</div>
                  </div>
                  <!-- <div
                    class="btn-item"
                    v-if="pageDetail.config.page.props.otherConfig.isShowReport"
                  >
                    <img
                      class="item-icon"
                      src="../../assets/images/st-member-index/report-icon.png"
                    />
                    <div class="item-text">举报</div>
                  </div> -->
                  <!-- <div class="btn-item"  v-if="pageDetail.config.page.props.otherConfig.isShowReport">
                    <img class="item-icon" src="../../assets/images/st-member-index/chat-icon.png" />
                    <div class="item-text">私聊</div>
                  </div> -->
                </div>
                <div
                  class="bottom-right"
                  :style="{
                    marginLeft: !systemConfig.props.shareConfig.showShare
                      ? '0'
                      : '34px',
                  }"
                  v-if="systemConfig.props.otherConfig.isShowFollow"
                >
                  +关注
                </div>
                <div
                  class="bottom-right"
                  :style="{
                    marginLeft: !(
                      systemConfig.props.shareConfig.showShare ||
                      systemConfig.props.otherConfig.isShowFollow
                    )
                      ? '0'
                      : '34px',
                  }"
                  v-if="systemConfig.props.otherConfig.isShowPrivateChat"
                >
                  私聊
                </div>
              </div>
              <!-- 会员主页底部按钮模块悬浮模块 -->
              <div
                class="member-index-bottom-fixed"
                v-if="
                  pageDetail.system_page === 'memberIndex' &&
                  hiddenMemberIndexBtn &&
                  systemConfig.props.shareConfig.showShare
                "
              >
                <!-- <img
                  class="item-icon"
                  v-if="pageDetail.config.page.props.otherConfig.isShowReport"
                  src="../../assets/images/st-member-index/report-icon.png"
                /> -->
                <img
                  class="item-icon"
                  v-if="systemConfig.props.shareConfig.showShare"
                  src="../../assets/images/st-member-index/share-icon2.png"
                />
              </div>
            </template>
            <!-- 个人中心底部固定样式  -->
            <div
              class="me-page-bottom"
              v-if="pageDetail.system_page === 'me_new'"
            >
              <div class="user-outlog">
                <p>清除缓存</p>
                <img
                  class="handle-icon"
                  src="../../assets/images/st-me-page/handle-next.png"
                />
              </div>
              <div class="user-outlog">
                <p>退出账号</p>
                <img
                  class="handle-icon"
                  src="../../assets/images/st-me-page/handle-next.png"
                />
              </div>
              <div class="bottom-watermark">
                <div
                  class="mark-item"
                  v-for="(item, index) in bottomWatermarkList"
                  :key="index"
                >
                  <el-image
                    class="mark-icon"
                    v-if="item.type === 'icon'"
                    :src="item.icon"
                    fit="contain"
                  >
                  </el-image>
                  <p class="mark-content" v-if="item.type === 'content'">
                    {{ item.content }}
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="!pageDetail.config.list && !pageDetail.config.page"
              class="flex-center"
              style="height: 613px"
            >
              页面开发中…
            </div>
            <!-- 底部导航 -->
            <div
              v-if="isTabPage"
              :class="[
                'drag-item fixed no-drag no-hover',
                value === 'bottomNav' ? 'active' : '',
              ]"
              style="position: absolute; bottom: 0"
              @click.stop="updateSelected('bottomNav')"
            >
              <div style="position: relative; z-index: 1">
                <st-bottom-nav
                  :config="system['bottomNav'].props"
                  :value="bottomActive"
                />
                <page-middle-item-tool
                  :hide-delete="true"
                  :title="system['bottomNav'].title"
                  v-if="
                    system['bottomNav'].props.templateData.styleType ===
                    'template1'
                  "
                />
                <!-- <div
                  class="drag-item-tool"
                  v-if="
                    system['bottomNav'].props.templateData.styleType ===
                    'template1'
                  "
                >
                  <div class="arrow-label" v-if="system['bottomNav'].title">
                    {{ system['bottomNav'].title }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 右上角按钮组 -->
      <div
        class="workspace-middle-buttons"
        v-if="pageDetail.config.page && pageDetail.config.list"
      >
        <el-button
          v-if="pageDetail.system_page"
          :type="
            value ===
            (systemDict[pageDetail.system_page] || pageDetail.system_page)
              ? 'primary'
              : ''
          "
          @click.stop="
            updateSelected(
              systemDict[pageDetail.system_page] || pageDetail.system_page
            )
          "
        >
          <span class="middle-button-content">
            <img
              v-if="
                value ===
                (systemDict[pageDetail.system_page] || pageDetail.system_page)
              "
              src="../../assets/images/middle/page-setting-white.png"
            />
            <img v-else src="../../assets/images/middle/page-setting.png" />
            页面设置</span
          >
        </el-button>
        <el-button
          v-if="isDev"
          @click.stop="copyJSONConfig()"
          icon="el-icon-document-copy"
          >复制JSON</el-button
        >
        <el-button-group class="scale-control">
          <el-button
            @click="ratio -= 0.05"
            type=""
            icon="el-icon-minus"
          ></el-button>
          <el-button
            @click="ratio = 1"
            type=""
            icon="el-icon-c-scale-to-original"
          ></el-button>
          <el-button
            @click="ratio += 0.05"
            type=""
            icon="el-icon-plus"
          ></el-button>
        </el-button-group>
      </div>
    </template>
    <template v-else>
      <div class="workspace-middle-box" style="padding-top: 20vh">
        <el-empty description="页面不存在" />
      </div>
    </template>

    <!-- 边缘区域的组件们 -->

    <!-- 右下角撤销、重做 -->
    <div
      class="workspace-middle-undo-manage"
      v-if="pageDetail && (pageDetail.system_page || pageDetail.id)"
    >
      <el-tooltip class="item" content="撤销" effect="dark" placement="top">
        <img
          :disabled="!undoManagerUI.canUndo"
          @click.stop="undo"
          src="../../assets/images/middle/undo.png"
        />
      </el-tooltip>
      <el-tooltip class="item" content="恢复" effect="dark" placement="top">
        <img
          :disabled="!undoManagerUI.canRedo"
          @click.stop="redo"
          src="../../assets/images/middle/redo.png"
        />
      </el-tooltip>
      <!--         <img @click="restore" :disabled="!canRedo" /> -->
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

import StSubscribeMsg from '../Preview/StSubscribeMsg'
import StOnlineLearning from '../Preview/StOnlineLearning'
import StCollegeTeacherList from '../Preview/StCollegeTeacherList'
import StProceedings from '../Preview/StProceedings'
import StFollowUs from '../Preview/StFollowUs'
import StContactUs from '../Preview/StContactUs'
import StCommunityNav from '../Preview/StCommunityNav'
import StCommunitySquare from '../Preview/StCommunitySquare'
import StCommunityNavOld from '../Preview/StCommunityNavOld'
import StCommunityTalent from '../Preview/StCommunityTalent'
import StCircleGroup from '../Preview/StCircleGroup'
import StHotTopic from '../Preview/StHotTopic'
import StRelatedCompany from '../Preview/StRelatedCompany'
import StQuickJoin from '../Preview/StQuickJoin'
import StAboutMe from '../Preview/StAboutMe'
import StBroadcast from '../Preview/StBroadcast'
import StHonorCertificate from '../Preview/StHonorCertificate'
import StNewTrends from '../Preview/StNewTrends'
import StMemberMessage from '../Preview/StMemberMessage'
import StMessageFeedbackContent from '../Preview/StMessageFeedbackContent'
import StBusinessAssociationHome from '../Preview/StBusinessAssociationHome'
import StBusinessAssociationList from '../Preview/StBusinessAssociationList'
import StBusinessAssociation from '../Preview/StBusinessAssociation'
import StDemandList from '../Preview/StDemandList'

import StMemberIndex from '../Preview/StMemberIndex'
import StActivityDetail from '../Preview/StActivityDetail'
import StActivityAlbumDetail from '../Preview/StActivityAlbumDetail'
import StMailList from '../Preview/StMailList'
import StMailContent from '../Preview/StMailContent'
import StJoinOrganizeForm from '../Preview/StJoinOrganizeForm'
import StCompanyLibrary from '../Preview/StCompanyLibrary'
import StMap from '../Preview/StMap'
import StCompanyContact from '../Preview/StCompanyContact'
import StFreeContainer from '../Preview/StFreeContainer'
import StContactMe from '../Preview/StContactMe'
import StOldMePage from '../Preview/StOldMePage'
import StUserCenterNav from '../Preview/StUserCenterNav'
import StDefaultUserCenterNav from '../Preview/StDefaultUserCenterNav'
import StJournalList from '../Preview/StJournalList'
import StEmployCareerList from '../Preview/StEmployCareerList'
import StShopGoods from '../Preview/StShopGoods'
import StArticleDetail from '../Preview/StArticleDetail'
import StElectronCard from '../Preview/StElectronCard'
import StLevelSystem from '../Preview/StLevelSystem'
import StArticleComment from '../Preview/StArticleComment'
import StArticleLiked from '../Preview/StArticleLiked'
import StArticleContent from '../Preview/StArticleContent'
import StPageSet from '../Preview/StPageSet'
import StSpecialArticle from '../Preview/StSpecialArticle'
import StActivityPhoto from '../Preview/StActivityPhoto'
import StLiveList from '../Preview/StLiveList'
import StLivePlugin from '../Preview/StLivePlugin'
import StCustomHeader from '../Preview/st-custom-header/StCustomHeader'
import StCustomNav from '../Preview/StCustomNav'
import StMembershipDynamics from '../Preview/StMembershipDynamics'
import StBrandList from '../Preview/StBrandList'
import StSpiritList from '../Preview/StSpiritList'
import StText from '../Preview/StText'
import StLink from '@/modules/app-design/components/Preview/StLink'
import stBanner from '../Preview/StBanner'
import StImage from '../Preview/StImage'
import StBottomNav from '../Preview/StBottomNav'
import StImageTextNav from '../Preview/StImageTextNav'
import StShopCategories from '../Preview/StShopCategories'
import StShopCategoryPage from '../Preview/st-shop-category-page/StShopCategoryPage'
import StSearch from '../Preview/StSearch'
import StStartPage from '../Preview/StStartPage'
import StLoginPage from '../Preview/StLoginPage'
import StActivityList from '../Preview/st-activity-list/st-activity-list'
import StActivityListGov from '../Preview/StActivityListGov'
import StArticleList from '../Preview/StArticleList'
import StBottomWatermark from '../Preview/StBottomWatermark'
import StMePage from '../Preview/StMePage'
import StJoinOrganize from '../Preview/StJoinOrganize'
import StSource from '../Preview/StSource'
import StActivityImages from '../Preview/StActivityImages'
import StMessageFeedback from '../Preview/StMessageFeeback'
import StShopIndex from '../Preview/StShopIndex'
// import StIntegralShopIndex from "../Preview/StShopIndex";
import StColumnNavigation from '../Preview/StColumnNavigation'

import StServiceProvider from '../Preview/StServiceProvider'
import StChatMsgList from '../Preview/StChatMsgList'
import StChatMsg from '../Preview/StChatMsg'
import StArticleSubject from '../Preview/StArticleSubject'
import StVenue from '../Preview/StVenue'
import StInterconnectResource from '../Preview/StInterconnectResource'

import StMultiLevelList from '../Preview/StMultiLevelList'
import StContacts from '../Preview/StContacts'
import StOfficialAccount from '../Preview/StOfficialAccount'
import StStructure from '@/modules/app-design/components/Preview/StStructureV2'
import StArticleDetailAdvance from '@/modules/app-design/components/Preview/StArticleDetailAdvance'
import StSchoolContactList from '@/modules/app-design/components/Preview/StSchoolContactList'
import StDonationList from '@/modules/app-design/components/Preview/StDonationList'

import StVideo from '../Preview/StVideo'

import Compare from '@/base/utils/object-compare.js'

import UndoManager from '../../../../base/utils/undo-manager'
var undoManager = UndoManager
var cameraPhotos = []

import filesUpload from '@/base/utils/upload4'

import systemDict from '../../assets/jsons/system-page.json'
import StTheme from '../Preview/StTheme'
import StShare from '../Preview/StShare'
import StPosterShare from '../Preview/StPosterShare'
let library = require('../../assets/jsons/component-library.json')

import bus from '../../assets/js/bus.js'
import StEnterpriseListGov from '@/modules/app-design/components/Preview/StEnterpriseListGov'
import StChannelsVideoList from '@/modules/app-design/components/Preview/StChannelsVideoList'
import StChannelsLiveList from '@/modules/app-design/components/Preview/StChannelsLiveList'
import StInvitationPage from '@/modules/app-design/components/Preview/StInvitationPage'

import previewComponents from '@/modules/app-design/assets/js/preview-component'

import { getCustomHeaderPeople } from '../../api/components'
import ColorCustomIcon from '@/modules/app-design/components/Normal/ColorCustomIcon'

const JUMP_LINK_MODEL = require('@/modules/app-design/assets/jsons/jump-link.json')

import { toBlob } from 'html-to-image'
import { copyText } from '@/base/utils/tool'
import { formatConfigData } from '@/modules/app-design/assets/js/tool'
import PageMiddleItemTool from './PageMiddleItemTool'
import { randomString } from '../../../../base/utils/tool'

export default {
  name: 'PageMiddle',
  components: {
    PageMiddleItemTool,
    ...previewComponents,
    StLink,
    StChatMsgList,
    StChatMsg,
    StInterconnectResource,
    StArticleSubject,
    StServiceProvider,
    StVenue,
    StSchoolContactList,
    StSubscribeMsg,
    StOnlineLearning,
    StCollegeTeacherList,
    StChannelsLiveList,
    StChannelsVideoList,
    StProceedings,
    StArticleDetailAdvance,
    StColumnNavigation,
    StFollowUs,
    StContactUs,
    StCommunityNav,
    StCommunitySquare,
    StCommunityNavOld,
    StJournalList,
    StEmployCareerList,
    StCommunityTalent,
    StCircleGroup,
    StHotTopic,
    StRelatedCompany,
    StAboutMe,
    StBroadcast,
    StHonorCertificate,
    StNewTrends,
    StMemberMessage,
    StMessageFeedbackContent,
    StQuickJoin,
    StBusinessAssociationHome,
    StBusinessAssociationList,
    StEnterpriseListGov,
    StBusinessAssociation,
    StMemberIndex,
    StActivityDetail,
    StActivityAlbumDetail,
    StStructure,
    StMailList,
    StMailContent,
    StJoinOrganizeForm,
    StCompanyLibrary,
    StMap,
    StCompanyContact,
    StFreeContainer,
    StContactMe,
    StOldMePage,
    StUserCenterNav,
    StDefaultUserCenterNav,
    StShopGoods,
    StArticleDetail,
    StElectronCard,
    StLevelSystem,
    StArticleComment,
    StDemandList,
    StArticleLiked,
    StArticleContent,
    StSpecialArticle,
    StActivityPhoto,
    StLiveList,
    StLivePlugin,
    StPosterShare,
    StShare,
    StTheme,
    StMePage,
    StStartPage,
    StLoginPage,
    StSearch,
    StText,
    stBanner,
    StImage,
    StBottomNav,
    StImageTextNav,
    StPageSet,
    StCustomHeader,
    StCustomNav,
    StBrandList,
    StSpiritList,
    StActivityList,
    StActivityListGov,
    StArticleList,
    StBottomWatermark,
    StJoinOrganize,
    StSource,
    StActivityImages,
    StMessageFeedback,
    StMultiLevelList,
    StContacts,
    StOfficialAccount,
    draggable,
    StDonationList,
    StInvitationPage,
    StShopIndex,
    // StIntegralShopIndex,
    StShopCategories,
    StShopCategoryPage,
    ColorCustomIcon,
    StMembershipDynamics,
    StVideo,
  },
  provide() {
    return {
      getBottomWatermarkList: () => this.bottomWatermarkList,
      // ratio: this.ratio,
      getFullscreen: (fullscreen) => {
        this.fullscreen = fullscreen
      },
      toggleJoinFloat: (bol) => {
        this.joinFloat = bol
      },
      setting: this.setting,
    }
  },
  props: {
    // main.selected (v-model)
    value: {
      type: [String, Number],
    },
    // 自定义页面相关
    page: Object,
    list: Array,
    // 系统页面
    system: Object,
    // 系统设置
    setting: Object,
    rulesArr: [Object, Array],
  },
  data() {
    return {
      joinFloat: true,
      fullscreen: false, // 页面(PageView)全屏，就是middle隐藏头部导航，让内部（PageView）自己实现
      /* 轮播 current url */
      topBannerCurrent: '',
      ratio: 1,
      undoManagerUI: {
        // 撤销重做
        canUndo: false,
        canRedo: false,
      },
      listOptions: {
        group: {
          name: 'drag',
        },
        dragClass: 'chosen',
      },
      myList: [],
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      bottomActive: -1,
      systemDict: systemDict,
      baseUrl:
        window.serverConfig.VUE_APP_ADMINURL || 'https://saasapi.shetuan.pro',
      havePageLoadingComponent: [
        'st-article-list',
        'st-activity-list',
        'st-activity-photo',
        'st-brand-list',
        'st-live-list',
        'st-shop-goods',
        'st-membership-dynamics',
        'st-multi-level-list',
        'st-special-article',
        'st-channels-video-list',
        'st-channels-live-list',
        'st-activity-posts',
      ],
      systemPagePaddingBottom: {
        memberIndex: 70,
      },
      isH5: this.$route.params.platform === 'h5',
      isTestVer:
        window.serverConfig.VUE_APP_COPYCONFIG_BASEURL === document.domain,
      captureLoading: false,

      communityNavConfig: {},

      peopleData: {
        count: 0,
      },
    }
  },
  computed: {
    ...mapState('appDesign', [
      'pageDetail',
      'clone',
      'library',
      'paneName',
      'componentListConfig',
    ]),
    isFeatureConsult() {
      return this.$store.getters.tenant.features.includes('consult')
    },
    isDev() {
      return window.serverConfig.VUE_APP_COPYCONFIG_BASEURL === document.domain
      // return process.env.VUE_APP_MODE === 'development'
    },
    systemBottomNavLength() {
      let l = 0
      let hasBottomNav =
        this.system &&
        this.system['bottomNav'] &&
        this.system['bottomNav'].props &&
        this.system['bottomNav'].props.tabs &&
        this.system['bottomNav'].props.tabs.list &&
        this.system['bottomNav'].props.tabs.list.length > 0
      if (hasBottomNav) l = this.system['bottomNav'].props.tabs.list.length
      return l
    },
    /**
     * 判断底部导航是否有关联该页面，
     * 包括隐藏了的，控制能否添加系统导航关联
     */
    hasTabPage() {
      let hasBottomNav = this.systemBottomNavLength > 0
      /* 当前编辑的页面是否为tab页 */
      let hasTabPage = false
      this.bottomActive = -1
      if (hasBottomNav) {
        for (let index = 0; index < this.systemBottomNavLength; index++) {
          let tab = this.system['bottomNav'].props.tabs.list[index]
          let view = tab.jump_link?.jump_config?.view
          if (view) {
            if (this.pageDetail.id && view.id === this.pageDetail.id) {
              this.bottomActive = index
              hasTabPage = true
              break
            } else if (
              this.pageDetail.system_page &&
              view.system_page === this.pageDetail.system_page
            ) {
              this.bottomActive = index
              hasTabPage = true
              break
            }
          }
        }
      }
      return hasTabPage
    },
    /**
     * 过滤了不显示的底部导航数据，控制界面展示
     * @returns {boolean}
     */
    isTabPage() {
      let hasBottomNav = this.systemBottomNavLength > 0
      /* 当前编辑的页面是否为tab页 */
      let isTabPage = false
      this.bottomActive = -1
      if (hasBottomNav) {
        for (let index = 0; index < this.systemBottomNavLength; index++) {
          let tab = this.system['bottomNav'].props.tabs.list[index]
          if (tab.is_show) {
            let view = tab.jump_link?.jump_config?.view
            if (view) {
              if (this.pageDetail.id && view.id === this.pageDetail.id) {
                this.bottomActive = index
                isTabPage = true
                break
              } else if (
                this.pageDetail.system_page &&
                view.system_page === this.pageDetail.system_page
              ) {
                this.bottomActive = index
                isTabPage = true
                break
              }
            }
          }
        }
      }
      this.$emit('isTabPage', isTabPage)
      return isTabPage
    },
    // 系统页页面装修数据
    systemConfig() {
      let sign = this.pageDetail.system_page
      let selectedName = systemDict[sign] || sign
      return sign
        ? this.system[selectedName] || this.setting[selectedName]
        : null
    },
    // 自定义导航
    hasCustomNav() {
      let bol = false
      let li = this.myList
      if (li.length === 0) return false
      let last = li.length - 1
      if (
        li &&
        li[last] &&
        ['st-custom-nav', 'st-membership-dynamics'].includes(li[last].component)
      )
        bol = true
      return bol
    },
    hasCommunityNav() {
      if (this.myList.length === 0) return false
      this.communityNavConfig = this.myList.find(
        (m) =>
          m.component === 'st-community-nav' ||
          m.component === 'st-community-nav-old' ||
          m.component === 'st-community-square'
      )
      return !!this.communityNavConfig
    },
    hasBottomNavComponent() {
      return !!this.myList.find((m) => m.component === 'st-bottom-nav')
    },
    // 头部跳转链接真实数据
    headerJumpList() {
      return this.setting.headerJump.props.tabs.list[0].is_show !== undefined
        ? this.setting.headerJump.props.tabs.list.filter(
            (el) => el.is_show === 1
          )
        : this.setting.headerJump.props.tabs.list
    },
    // 自定义头部
    hasCustomHeader() {
      let bol = false
      let li = this.myList
      if (li && li[0] && li[0].component === 'st-custom-header') {
        bol = true
      } else if (
        li.findIndex((l) => l.component === 'st-custom-header') !== -1
      ) {
        bol = true
      }
      return bol
    },
    // 轮播模板1-联动页面背景
    hasTopBannerRelative() {
      let bol = false
      let li = this.myList
      if (li && li[0] && li[0].component === 'st-banner') {
        if (
          li[0].props.templateData.styleType === 'template1' &&
          li[0].props.templateData.relative
        )
          bol = true
      }
      return bol
    },
    // 文章详情评论栏
    articleCommentTab() {
      return this.pageDetail.config.list.findIndex(
        (el) => el.component === 'st-article-comment-tab'
      ) !== -1
        ? this.myList[this.myList.length - 1]
        : null
    },
    /* 是否正在编辑系统设置的内容 */
    isSystemActive() {
      if (this.pageDetail) return false
      return Object.keys(this.setting).includes(this.value)
    },
    /* 分别设置系统页面背景色 */
    systemPageBgColor() {
      let bgColor
      switch (this.pageDetail.system_page) {
        case 'me':
          bgColor = '#f5f6f7'
          break
        case 'organization':
          bgColor = '#ebebeb'
          break
        case 'activity_detail':
          bgColor = this.systemConfig.props.normal.bgColor
          break
        default:
          bgColor = ''
      }
      return bgColor
    },
    // "theme": ["#3576FF", "#E2EBFF", "#FFFFFF"],
    mainColor() {
      if (!this.setting || !this.setting.theme) {
        return '#3576FF'
      }
      let props = this.setting.theme.props.normal
      if (props.isCustom) return props.mainColor
      return props.theme[0]
    },
    helpColor() {
      if (!this.setting || !this.setting.theme) {
        return '#E2EBFF'
      }
      let props = this.setting.theme.props.normal
      if (props.isCustom) return props.helpColor
      return props.theme[1]
    },
    textColor() {
      if (!this.setting || !this.setting.theme) {
        return '#FFFFFF'
      }
      let props = this.setting.theme.props.normal
      if (props.isCustom) return '#ffffff'
      return props.theme[2]
    },
    // 自定义页面缺省页点击事件参数
    pane_name: {
      get() {
        return this.paneName
      },
      set(val) {
        this.$store.commit('appDesign/setPaneName', val)
      },
    },
    // 底部品牌版权配置
    bottomWatermarkList() {
      return this.setting.bottomWatermark.props.tabs.list.filter(
        (item) => item.is_show === 1
      )
    },
    // 会员主页底部按钮显示隐藏
    hiddenMemberIndexBtn() {
      if (this.pageDetail.system_page === 'memberIndex') {
        let pageConfig = this.systemConfig.props
        return (
          !pageConfig.otherConfig.isShowFollow &&
          !pageConfig.otherConfig.isShowPrivateChat
        )
      } else {
        return false
      }
    },
  },
  watch: {
    // 判断文章列表是否可以分页加载
    value(val) {
      // console.log("val", val);

      if (typeof val == 'number' && val === this.myList.length - 1) {
        this.$store.commit('appDesign/setArticlePage', false)
      } else {
        this.$store.commit('appDesign/setArticlePage', true)
      }
      if (
        typeof val == 'number' &&
        val !== -1 &&
        this.myList.length &&
        this.myList[val].component === 'st-free-container'
      ) {
        this.$store.commit('appDesign/setIsFreeContainer', true)
      } else {
        this.$store.commit('appDesign/setIsFreeContainer', false)
        bus.$emit('hideComponents', true)
      }
    },
    list: {
      handler(newValue) {
        newValue.forEach((e1, ei) => {
          library.custom_components.forEach((e2) => {
            e2.components.forEach((e3) => {
              if (e1.component === e3.component) {
                if (e1.extra) {
                  e1.extra.version_different = e1.version !== e3.version
                } else {
                  e1.extra = {
                    version_different: e1.version !== e3.version,
                    timestamp: new Date().getTime() + ei * ei,
                  }
                }
              }
            })
          })

          Object.keys(library.system).forEach((key) => {
            if (e1.component === library.system[key].component) {
              if (e1.extra) {
                e1.extra.version_different =
                  e1.version !== library.system[key].version
              } else {
                e1.extra = {
                  version_different: e1.version !== library.system[key].version,
                  timestamp: new Date().getTime() + ei * ei,
                }
              }
            }
          })
        })
        this.myList = newValue
        // console.log(this.myList,'myList')
        // console.log(this.pageDetail,'pageDetail')
      },
      // 主要为了监听到列表项版本号的修改
      deep: true,
    },
    /**
     * 切换页面、更新修改时间都会触发
     * */
    pageDetail: {
      handler(val, oldVal) {
        if (val) {
          if (!Compare(val, oldVal)) {
            /* 清空可撤销的操作记录 */
            undoManager.clear()
            this.refreshUndoStatus()
          }
        }

        if (val && val.system_page === 'memberIndex') {
          let pageConfig = val.config.page.props
          this.systemPagePaddingBottom.memberIndex =
            !pageConfig.otherConfig.isShowFollow &&
            !pageConfig.otherConfig.isShowPrivateChat
              ? 0
              : 70
        }

        if (val?.id !== oldVal?.id || val?.system_page !== oldVal?.system_page) {
          // id 变了，TODO：不知道靠不靠谱呢
          this.fullscreen = false // 重置
        }
      },
      deep: true,
    },
    hasTopBannerRelative: {
      handler(val) {
        if (val) {
          this.queryBannerFirst()
        } else {
          this.topBannerCurrent = ''
        }
      },
      immediate: true,
    },
  },
  created() {
    getCustomHeaderPeople()
      .then((res) => {
        this.peopleData = res.data
      })
      .catch((err) => {
        console.log(err)
      })
    //判断版本是否不同
    this.list.forEach((e1) => {
      library.custom_components.forEach((e2) => {
        e2.components.forEach((e3) => {
          if (e1.component === e3.component) {
            e1.extra.version_different = e1.version !== e3.version
          }
        })
      })
    })
    this.myList = this.list
    // 监听接收组件库点击添加事件
    bus.$on('addComponent', (component) => {
      this.handleClickAdd(component)
    })

    undoManager.setCallback(this.refreshUndoStatus)
  },
  methods: {
    queryBannerFirst(times = 10) {
      if (times === 0) return
      setTimeout(() => {
        if (this.$refs.dynamicCom) {
          if (this.$refs.dynamicCom[0].myList) {
            if (this.$refs.dynamicCom[0].myList.length) {
              this.topBannerCurrent = this.$refs.dynamicCom[0].myList[0]?.image
            } else {
              this.queryBannerFirst(times - 1)
            }
          } else {
            this.queryBannerFirst(times - 1)
          }
        } else {
          this.queryBannerFirst(times - 1)
        }
      }, 500)
    },
    captureCurrentPage() {
      this.captureLoading = true
      // setTimeout(() => {
      const node = document.getElementById('capture')
      toBlob(node)
        .then((url) => {
          /* 新标签页打开预览，右键保存 */
          // window.open(URL.createObjectURL(url), "_blank");
          // this.captureLoading = false;
          let fileName = '截图' + new Date().getTime() + '.png'
          filesUpload({
            configApi: '/admin/admin/media/requestUpload',
            data: new window.File([url], fileName, { type: 'image/png' }),
          })
            .then((res) => {
              alert(res)
              // TODO: save cover
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
          this.captureLoading = false
        })
      // }, 1500)
    },
    addBottomNav() {
      let tabs = this.system['bottomNav'].props.tabs.list || []
      if (tabs.length >= 5) {
        this.$message.info('最多添加 5 个')
      } else {
        tabs.push({
          icon_checked: '',
          icon_normal: '',
          is_default: 0,
          name: this.pageDetail.name || '导航名称',
          is_show: 1,
          jump_link: this.$mergeModel(JUMP_LINK_MODEL, {
            jump_type: 'view',
            jump_config: {
              name: this.pageDetail.name,
              view: {
                id: this.pageDetail.id,
                is_system: this.pageDetail.system_page ? 1 : 0,
                system_page: this.pageDetail.system_page,
              },
            },
          }),
        })
      }
      this.updateSelected('bottomNav')
    },
    /* 获取底部内边框高度 */
    getPaddingBottomHeight(paddingBottom) {
      if (this.isTabPage) {
        return this.system['bottomNav'].props.templateData.styleType ===
          'template1'
          ? paddingBottom + 50
          : paddingBottom
      } else {
        return (
          paddingBottom +
          (this.systemPagePaddingBottom[this.pageDetail.system_page] || 0)
        )
      }
    },
    /* 刷新撤销/重做状态 */
    refreshUndoStatus() {
      this.undoManagerUI.canUndo = undoManager.hasUndo()
      this.undoManagerUI.canRedo = undoManager.hasRedo()
    },
    /* 注意两个拖拽容器都要配置才能禁止停靠 */
    onMove(e) {
      let flag = true
      const el = e.relatedContext.element
      /* 不允许拖拽、停靠（需配合draggable的filter属性 */
      if (
        el?.component === 'st-custom-header' ||
        (this.havePageLoadingComponent.includes(el?.component) &&
          el?.props.loadingConfig.loadingType === 'page')
      ) {
        flag = false
      }
      return flag
    },
    /**
     * 监听"页面"拖拽排序操作
     */
    handleSort(ev) {
      // console.log(ev, "ev");
      // 添加一个新的子项目时（handleAdd()），也会触发sort事件，所以使用此字段忽略后续操作
      if (this.isIgnore) {
        this.isIgnore = false
      } else {
        // console.log("sort", ev.newIndex);
        let realIndex = this.hasCustomHeader ? ev.newIndex + 1 : ev.newIndex
        let realOldIndex = this.hasCustomHeader ? ev.oldIndex + 1 : ev.oldIndex
        // 禁止其他组件放在设置了分页加载的文章列表组件之后，虽然分页加载的文章列表设置了禁止停靠属性，但还是以防万一吧

        // 自定义导航，会员动态组件不能拖，只能排最后
        if (this.myList[realIndex].component == 'st-membership-dynamics') {
          /* 触底加载组件往上跑，要还原 */
          this.myList.splice(realOldIndex + 1, 0, this.myList[realIndex])
          this.myList.splice(realIndex, 1)
          return
        }
        if (
          this.havePageLoadingComponent.includes(
            this.myList[realIndex].component
          ) &&
          this.myList[realIndex].props.loadingConfig.loadingType === 'page' &&
          ev.newIndex < ev.oldIndex
        ) {
          /* 触底加载组件往上跑，要还原 */
          this.myList.splice(realOldIndex + 1, 0, this.myList[realIndex])
          this.myList.splice(realIndex, 1)
          return
        }
        if (
          realIndex - 1 >= 0 &&
          this.myList[realIndex - 1] &&
          this.havePageLoadingComponent.includes(
            this.myList[realIndex - 1].component
          ) &&
          this.myList[realIndex - 1].props.loadingConfig.loadingType === 'page'
        ) {
          // ?? 这里表示的是，目标组件的上一个元素是触底加载组件？？？
          this.myList.splice(realOldIndex, 0, this.myList[realIndex])
          this.myList.splice(realIndex + 1, 1)
          return
        }

        // 旧代码开始
        // // console.log("sort real", realIndex);
        // let target = this.myList[realIndex]
        // this.$emit('update-list', this.myList)
        // this.updateSelected(realIndex)
        // undoManager.add({
        //   undo: () => {
        //     this.myList.splice(realIndex, 1)
        //     this.myList.splice(
        //         realOldIndex,
        //         0,
        //         JSON.parse(JSON.stringify(target))
        //     )
        //     this.$emit('update-list', this.myList)
        //   },
        //   redo: () => {
        //     this.myList.splice(realOldIndex, 1)
        //     this.myList.splice(realIndex, 0, JSON.parse(JSON.stringify(target)))
        //     this.$emit('update-list', this.myList)
        //   },
        // })
        // this.refreshUndoStatus()
        // 旧代码结束

        // 以下可以调整顺序
        this.onSortAction({
          oldIndex: realOldIndex,
          newIndex: realIndex,
          targetId: this.myList[realIndex].extra?.timestamp,
          id: randomString(), // 默认 32 长度
        })
      }
    },
    onSortAction(attrs) {
      let that = this
      const { oldIndex, newIndex, id, targetId } = attrs

      this.$emit('update-list', this.myList) // 进入此方法时，myList 已经是更新排序后的样子了
      this.updateSelected(newIndex) // 当时选中的

      // 记录一次操作对象
      undoManager.add({
        undo: function () {
          let attrs_list = that.myList // 最新的列表
          let target = attrs_list[attrs.newIndex] // 当时的元素
          attrs_list.splice(attrs.newIndex, 1) // 新位置的删掉
          attrs_list.splice(
            attrs.oldIndex,
            0,
            JSON.parse(JSON.stringify(target)) // 放回旧的位置
          )
          that.$emit('update-list', attrs_list)
        },
        redo: function () {
          // 排序的操作有些特殊，因为顺序已经由 draggable 处理好了，我们不需要手动调整
          const oldItems = that.myList.splice(oldIndex, 1) // 旧位置的元素移除
          that.myList.splice(
            newIndex,
            0,
            JSON.parse(JSON.stringify(oldItems[0]))
          ) // 新位置放旧位置的元素

          that.onSortAction(attrs)
        },
      })
    },
    onAddAction(attrs) {
      let that = this
      const { oldIndex, newIndex, target, id, targetId } = attrs

      let attrs_list = this.myList // 当时的列表

      // attrs_list.push(JSON.parse(JSON.stringify(target)))
      attrs_list.splice(newIndex, 0, JSON.parse(JSON.stringify(target)))

      this.$emit('update-list', attrs_list) // 进入此方法时，myList 已经是更新排序后的样子了
      this.updateSelected(attrs.newIndex) // 当时选中的

      // 记录一次操作对象
      undoManager.add({
        undo: function () {
          let attrs_list = that.myList // 一定要最新的列表
          attrs_list.splice(attrs.newIndex, 1) // 删掉
          that.$emit('update-list', attrs_list)
        },
        redo: function () {
          that.onAddAction(attrs)
        },
      })
    },
    /**
     * 从左侧列表添加了一个组件到"页面"
     */
    handleAdd(ev) {
      // 添加一个新的子项目时，也会触发sort事件，所以使用此字段忽略后续操作
      this.isIgnore = true

      // 被克隆拖拽的对象
      // console.log(this.clone.data)

      // 使目标对象更准确，防止混乱的旧数据影响位置顺序
      let nowIndex = this.myList.findIndex(
        (li) => li.extra.timestamp === this.clone.data.extra.timestamp
      )
      let target = JSON.parse(
        JSON.stringify(this.myList.splice(nowIndex, 1)[0])
      )
      // console.log('target', target.component, target)

      // console.log(this.myList);

      // 是否已存在自定义头部（需要排除当前克隆拖拽的对象刚好就是自定义头部）
      let hasCustomHeader =
        this.hasCustomHeader && this.clone.data.component !== 'st-custom-header'
      // console.log('hasCustomHeader', hasCustomHeader)

      /* 是否已存在底部导航组件 */
      let hasBottomNav =
        this.hasBottomNavComponent &&
        this.clone.data.component !== 'st-bottom-nav'
      // console.log('has bottom nav in handle add?', hasBottomNav)

      // 是否已存在社区导航
      let hasCommunityNav =
        this.hasCommunityNav &&
        ![
          'st-community-nav',
          'st-community-nav-old',
          'st-community-square',
        ].includes(this.clone.data.component)
      // console.log('hasCommunityNav', hasCommunityNav)

      let hasCustomNav =
        this.hasCustomNav &&
        ['st-custom-nav', 'st-membership-dynamics'].includes(
          this.clone.data.component
        )
      // console.log('hasCustomNav', hasCustomNav)

      /** 如果有自定义头部，需要加一得到真实下标 */
      let realIndex = hasCustomHeader ? ev.newIndex + 1 : ev.newIndex
      // console.log('ev.newIndex', ev.newIndex)
      // console.log('realIndex', realIndex)

      if (this.isSystemActive) {
        /* 系统设置预览，不能添加 */
      } else if (
        this.clone.data.extra.count >= this.clone.data.extra.maxCount
      ) {
        /* 超过上限，不能添加 */
      } else {
        if (this.clone.data.component === 'st-bottom-nav') {
          if (this.myList.length) {
            /* 已经存在其他组件，不能添加 */
          } else {
            if (this.isTabPage) {
              /* 属于系统设置底部导航的一页 */
            } else {
              this.onAddAction({
                // list: JSON.stringify(this.myList),
                target: JSON.parse(JSON.stringify(target)),
                newIndex: 0,
              })
              // this.myList.push(JSON.parse(JSON.stringify(target)))

              // this.updateSelected(0)
              // undoManager.add({
              //   undo: () => {
              //     this.myList.shift()

              //     this.$emit('update-list', this.myList)
              //   },
              //   redo: () => {
              //     this.myList.unshift(JSON.parse(JSON.stringify(target)))

              //     this.$emit('update-list', this.myList)
              //   },
              // })
            }
          }
        } else if (hasBottomNav) {
          /* 有了底部导航，就不能放其它组件了 */
        } else if (this.clone.data.component === 'st-custom-header') {
          // 某些组件只能放在数组第一项的位置
          this.onAddAction({
            // list: JSON.stringify(this.myList),
            newIndex: 0,
            target: JSON.parse(JSON.stringify(target)),
          })

          // this.myList.unshift(JSON.parse(JSON.stringify(target)))

          // this.updateSelected(0)
          // undoManager.add({
          //   undo: () => {
          //     this.myList.shift()

          //     this.$emit('update-list', this.myList)
          //   },
          //   redo: () => {
          //     this.myList.unshift(JSON.parse(JSON.stringify(target)))

          //     this.$emit('update-list', this.myList)
          //   },
          // })
          this.$nextTick(() => {
            var elem = document.getElementById(target.extra.timestamp)
            if (elem) elem.scrollIntoView()
          })
        } else if (
          this.clone.data.component === 'st-custom-nav' &&
          hasCommunityNav &&
          this.myList[this.myList.length - 1] &&
          this.havePageLoadingComponent.includes(
            this.myList[this.myList.length - 1].component
          ) &&
          this.myList[this.myList.length - 1].props.normal.loadingType ===
            'page'
        ) {
          /* 两个组件冲突了，只能存在其一 */
        } else if (
          ([
            'st-community-nav',
            'st-community-nav-old',
            'st-community-square',
          ].includes(this.clone.data.component) &&
            hasCustomNav) ||
          (this.clone.data.component === 'st-custom-nav' && hasCommunityNav)
        ) {
          /* 两个组件冲突了，只能存在其一 */
        } else if (
          this.havePageLoadingComponent.includes(
            this.myList[nowIndex - 1]?.component
          ) &&
          this.myList[nowIndex - 1]?.props.loadingConfig.loadingType === 'page'
        ) {
          /* 对于设置了分页加载的文章列表组件，其组件下方不允许添加组件 */
        } else if (this.clone.data.component === 'st-text') {
          /* 文字组件只能放置在自由容器中 */
        } else if (
          this.clone.data.component === 'st-custom-nav' ||
          this.clone.data.component === 'st-community-nav' ||
          this.clone.data.component === 'st-community-square' ||
          this.clone.data.component === 'st-community-nav-old' ||
          this.clone.data.component === 'st-membership-dynamics' ||
          this.clone.data.component === 'st-activity-posts'
        ) {
          // console.log(this.clone.data.component, 'addComponent')
          // 某些组件只能放在数组最后的位置
          this.onAddAction({
            // list: JSON.stringify(this.myList),
            // 新的位置下标，此时新元素还没加入，即下标内容还是undefined
            newIndex: this.myList.length, // 不应该用 len - 1。len - 1 已经存在一个元素，splice 会使原来的元素后移
            target: JSON.parse(JSON.stringify(target)),
          })
          // this.myList.push(JSON.parse(JSON.stringify(target)))

          // this.updateSelected(this.myList.length - 1)
          // undoManager.add({
          //   undo: () => {
          //     this.myList.pop()

          //     this.$emit('update-list', this.myList)
          //   },
          //   redo: () => {
          //     this.myList.push(JSON.parse(JSON.stringify(target)))

          //     this.$emit('update-list', this.myList)
          //   },
          // })

          this.$nextTick(() => {
            var elem = document.getElementById(target.extra.timestamp)
            if (elem) elem.scrollIntoView()
          })
        } else {
          // 放置普通组件
          this.onAddAction({
            // list: JSON.stringify(this.myList),
            newIndex: realIndex,
            target: JSON.parse(JSON.stringify(target)),
          })
          // this.myList.splice(realIndex, 0, JSON.parse(JSON.stringify(target)))

          // this.updateSelected(realIndex)
          // undoManager.add({
          //   undo: () => {
          //     this.myList.splice(realIndex, 1)

          //     this.$emit('update-list', this.myList)
          //   },
          //   redo: () => {
          //     this.myList.splice(
          //       realIndex,
          //       0,
          //       JSON.parse(JSON.stringify(target))
          //     )

          //     this.$emit('update-list', this.myList)
          //   },
          // })
        }
      }
      // console.log(this.myList)
      // this.$emit('update-list', this.myList)
      // this.refreshUndoStatus()
    },
    /**
     * 从左侧列表通过点击添加了一个组件到"页面"
     */
    handleClickAdd(component) {
      let target = component
      if (target.component === 'st-custom-header') {
        console.log(this.myList, '1')
        // 某些组件只能放在数组第一项的位置
        this.myList.unshift(JSON.parse(JSON.stringify(target)))

        this.updateSelected(0)
        undoManager.add({
          undo: () => {
            this.myList.shift()

            this.$emit('update-list', this.myList)
          },
          redo: () => {
            this.myList.unshift(JSON.parse(JSON.stringify(target)))

            this.$emit('update-list', this.myList)
          },
        })
      } else if (
        this.myList.findIndex((el) =>
          [
            'st-custom-nav',
            'st-community-nav',
            'st-community-square',
            'st-membership-dynamics',
            'st-community-nav-old',
            'st-article-comment-tab',
          ].includes(el.component)
        ) !== -1
      ) {
        console.log(this.myList, '2')
        this.myList.splice(
          this.myList.length - 1,
          0,
          JSON.parse(JSON.stringify(target))
        )
        this.updateSelected(this.myList.length - 2)
        undoManager.add({
          undo: () => {
            this.myList.pop()

            this.$emit('update-list', this.myList)
          },
          redo: () => {
            this.myList.push(JSON.parse(JSON.stringify(target)))

            this.$emit('update-list', this.myList)
          },
        })
      } else {
        console.log(this.myList, '3')
        this.myList.push(JSON.parse(JSON.stringify(target)))

        this.updateSelected(this.myList.length - 1)
        undoManager.add({
          undo: () => {
            this.myList.pop()
            this.$emit('update-list', this.myList)
          },
          redo: () => {
            this.myList.push(JSON.parse(JSON.stringify(target)))

            this.$emit('update-list', this.myList)
          },
        })
      }
      this.$emit('update-list', this.myList)
      this.refreshUndoStatus()
      this.$nextTick(() => {
        let elem = document.getElementById(target.extra.timestamp)
        if (elem) elem.scrollIntoView({
          behavior: 'smooth', // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: 'center', // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: 'nearest', // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })
      })
    },
    /* 删除 */
    deletePageItem(i) {
      let target = JSON.parse(JSON.stringify(this.myList.splice(i, 1)[0]))

      this.$emit('update-list', this.myList)

      // 刷新完列表再设置 selected
      if (typeof this.value === 'number' && this.value !== -1) {
        /* 删除后要修改selected */
        if (i > this.value) {
          /* 不用管 */
        } else if (i === this.value) {
          /* 如果后面还有元素，选中那个元素，否则-1 */
          if (!this.myList[i + 1]) {
            this.updateSelected()
          }
        } else if (i < this.value) {
          this.updateSelected(this.value - 1)
        }
      }
      undoManager.add({
        undo: () => {
          // 在 i 位置插入一个元素
          this.myList.splice(i, 0, JSON.parse(JSON.stringify(target)))
          this.updateSelected(i)
          this.$emit('update-list', this.myList)
        },
        redo: () => {
          // 删除 i 位置的元素
          // this.myList.splice(i, 1)

          // this.$emit('update-list', this.myList)
          this.deletePageItem(i)
        },
      })
      this.refreshUndoStatus()
      /* 重置分页加载组件可配置状态 */
      if (
        typeof this.value == 'number' &&
        this.value === this.myList.length - 1
      ) {
        this.$store.commit('appDesign/setArticlePage', false)
      } else {
        this.$store.commit('appDesign/setArticlePage', true)
      }
    },
    /**
     * @deprecated
     * $emit('update-list')已处理
     * 修改组件使用次数
     * @param plus
     * @param target
     */
    updateCount(plus, target) {
      /* 组件使用次数+1 */
      // let newLibrary = JSON.parse(JSON.stringify(this.library));
      // if (plus) {
      //   /* 增加 */
      //   newLibrary["custom_components"].forEach((lib) => {
      //     lib.components.forEach((c) => {
      //       if (c.component === (target ? target.component : this.clone.data.component)) {
      //         c.extra && c.extra.count++;
      //       }
      //     });
      //   });
      // } else {
      //   /* 减少 */
      //   if (target.extra && target.extra.type) {
      //     /* 如果已经知道是哪一个分组（基础、业务、微信小程序） */
      //     for (let k = 0; k < newLibrary["custom_components"].length; k++) {
      //       if (newLibrary["custom_components"][k].index === target.extra.type) {
      //         newLibrary["custom_components"][k].components.forEach((c) => {
      //           if (c.component === target.component) {
      //             c.extra && c.extra.count--;
      //           }
      //         });
      //         break;
      //       }
      //     }
      //   } else {
      //     newLibrary["custom_components"].forEach((lib) => {
      //       lib.components.forEach((c) => {
      //         if (c.component === target.component) {
      //           c.extra && c.extra.count--;
      //         }
      //       });
      //     });
      //   }
      // }
      // this.$store.commit("appDesign/setLibrary", newLibrary);
      this.$emit('update-list', this.myList)
    },
    updateSelected(key) {
      // console.log(key, this.value);
      if (key === undefined) {
        if (this.pageDetail) {
          let sp = this.pageDetail.system_page
          if (sp) key = systemDict[sp] || sp
          else if (this.pageDetail.id) key = 'page'
        }
      }
      this.$emit('input', key)
      if (this.list[key] && this.list[key].component !== 'st-free-container') {
        /** 重置自由容器子组件下标 */
        let c = JSON.parse(JSON.stringify(this.componentListConfig))
        c.free_container_select = -1

        this.$store.commit('appDesign/setStateAttr', {
          key: 'componentListConfig',
          val: c,
        })
      }
    },
    undo() {
      this.updateSelected('page')
      if (this.undoManagerUI.canUndo) {
        undoManager.undo()
        this.refreshUndoStatus()
      } else {
        this.$message.info('不能再撤回了')
      }
    },
    redo() {
      this.updateSelected('page')
      if (this.undoManagerUI.canRedo) {
        undoManager.redo()
        this.refreshUndoStatus()
      } else {
        this.$message.info('不能再恢复了')
      }
    },
    // 点击添加组件
    handleAddComp() {
      if ((this.pane_name = 'component-library')) {
        bus.$emit('activeComponentLib')
      } else {
        this.pane_name = 'component-library'
      }
    },
    // 检查点击元素
    // clickTraget(ev) {
    //   console.log("clickTraget", ev);
    // },
    /**
     * 复制系统页 JSON，用来方便设置超级后台系统页默认数据JSON
     */
    copyJSONConfig() {
      copyText(
        JSON.stringify(
          formatConfigData(
            {
              page: this.systemConfig,
              list: this.list,
            },
            this.systemDict[this.pageDetail.system_page] ||
              this.pageDetail.system_page
          )
        )
      )
        .then((res) => {
          this.$message.success('复制成功')
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.page-middle {
  position: relative;
  height: 100%;
  ::v-deep.el-image__error {
    display: none;
  }

  @media screen and (max-width: 1330px) {
    .workspace-middle-page {
      transform: scale(0.9);
    }

    .workspace-middle-buttons {
      flex-direction: row;
      top: 0;

      & > .el-button + .el-button,
      & > .el-button + .el-button-group {
        margin-left: 10px;
      }
    }

    .middle-button-content {
      img {
        margin-right: 0 !important;
      }

      .button-text {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .workspace-middle-page {
      transform: scale(0.8);
    }
  }
}

.workspace-middle-box {
  padding: 63px 0 80px;
  overflow-y: auto;
  height: 100%;

  .workspace-middle-page {
    box-shadow: 0 4px 8px 0 #d5deeb;
    margin: 0 auto;
    width: 375px;
    min-height: 667px;
    position: relative;
    background-size: 100% auto;
    background-repeat: repeat-y;
    // background-color: white;
    transform-origin: center 0;

    &.analysis,
    &.analysis_user {
      background-repeat: no-repeat;
    }

    &.no-hover {
      cursor: default !important;

      &::before {
        display: none;
      }

      .drag-item {
        &:hover {
          outline: none;
        }

        &.active {
          outline: none;
        }
      }
    }
  }

  .bottomWatermark {
    position: absolute;
    bottom: 40px;
  }

  .workspace-middle-page-content {
    position: relative;
    min-height: 667px;

    &:before {
      content: 'iPhone 8 手机高度';
      font-size: 12px;
      line-height: 17px;
      padding: 3px 12px 3px 0;
      color: #3a3a3a;
      position: absolute;
      top: 643px;
      border-bottom: 1px solid #606266;
      transform: translateX(-100%);
    }

    $fixed-bottom: 175px;
    $fixed-right: 24px;
    $fixed-btn-size: 54px;

    .aside-fixed {
      position: absolute;
      width: $fixed-btn-size;
      height: $fixed-btn-size;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(233, 233, 233, 0.5);
      border: 1px solid #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      bottom: $fixed-bottom;

      &.consult {
        width: 52px;
        height: 52px;
        background-color: rgb(14, 117, 98);

        .aside-icon {
          width: 28px;
          height: 28px;
        }
      }

      &.left {
        left: $fixed-right;
      }

      &.right {
        right: $fixed-right;
      }

      .aside-icon {
        width: 32px;
        height: 30px;
      }
    }

    /* 自定义页面缺省页样式 */
    .none-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .none-list-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .none-list-img {
          width: 188px;
          height: 201px;
        }

        .none-list-tip {
          margin-top: 22px;
          font-size: 14px;
          color: #7f7f7f;
          line-height: 20px;
        }
      }
    }
  }
}

/* 固定按钮：撤销、重做 */
.workspace-middle-undo-manage {
  position: absolute;
  right: 32px;
  bottom: 10%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 25px;
  z-index: 11;
  height: 42px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #d5deeb;
  border-radius: 21px;
  padding: 0 6px;

  img {
    width: 28px;
    margin: 0 6px;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

/* 固定按钮 */
.workspace-middle-buttons {
  position: absolute;
  top: 63px;
  right: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 4;

  .el-button {
    margin-left: 0;
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 10px;
    border: none;
    box-shadow: 0 2px 4px 0 #d5deeb;
    min-height: 32px;
  }

  & > .el-button {
    width: 100%;
    /*width: 100px;*/
  }

  .middle-button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 16px;
    }
  }

  .el-button-group {
    flex-shrink: 0;
  }
}

.different-version-tip {
  background: #ffcb50;
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  z-index: 2;
}

.drag-item-tool {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  top: 0;

  .tools {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 0 2px 2px 0;
    cursor: default;

    .tool {
      cursor: pointer;
      width: 100%;
      height: 40px;
    }

    img {
      font-size: 18px;
      width: 20px;
    }
  }

  .arrow-label {
    display: block;
    position: absolute;
    right: 28px;
    transform: translateX(100%);
    top: 0;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #3a3a3a;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::before {
      content: '\00a0';
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 16px 7px 0;
      border-color: transparent white transparent transparent;
      position: absolute;
      left: -15%;
    }
  }
}

.status-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20px !important;
  z-index: 2;
}

.drag-item {
  width: 100%;
  position: relative;
  outline: none;

  &.is-different {
    outline: 2px solid #ffcb50;
    z-index: 1;

    &:hover {
      outline: 2px solid #ffcb50;
    }
  }

  &.fixed {
    position: absolute;
    bottom: 0;
    cursor: pointer !important;
  }

  &:hover {
    outline: 2px dashed #3479ef;
    z-index: 2;
    // outline-offset: -2px;
    cursor: move;

    .tools,
    ::v-deep .tools {
      display: flex;
    }

    .arrow-label,
    ::v-deep .arrow-label {
      display: none;
    }
  }

  &.no-hover {
    cursor: default;

    &.header {
      outline: none;
    }
  }

  &.no-drag {
    cursor: pointer;
  }

  &.active {
    outline: 2px solid #3479ef;
    z-index: 2;
    // outline-offset: -2px; /* 比如在自定义导航上就看不见了 */
  }

  .resize {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: s-resize;
  }
}

.draggable-box {
  /*padding-bottom: 2px;*//* 这个做法的用处是什么？？ */

  &.system {
    min-height: 500px;
  }
  .forbid {
    cursor: pointer;
  }
}

/* 系统自定义页面组件 */
.page-header {
  width: 375px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  .start-top-img {
    width: 375px;
    height: 65px;
    background-color: white;
  }

  .page-title {
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -36px;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
}

// 文章详情评论，点赞、收藏、转发区域样式
.article-commemt {
  // height: 48px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  padding: 9px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 4px 0px rgba(238, 238, 238, 0.5);
  .handle-left {
    width: 232px;
    height: 30px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    .left-content {
      display: flex;
      align-items: center;
      .input-icon {
        font-size: 15px;
        margin-right: 5px;
      }
      .input-tips {
        font-size: 13px;
        line-height: 13px;
      }
    }
  }
  .handle-right {
    display: flex;
    align-items: center;
    .handle-item + .handle-item {
      margin-left: 11px;
    }
    .handle-item {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .handle-icon {
        width: 24px;
        height: 24px;
      }
      .handle-name {
        font-size: 9px;
        color: #4a4c4f;
      }
    }
  }
}

// 信息反馈按钮样式
.submit-btn {
  padding: 13px 18px;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 70px;
  background: #ffffff;
  .submit-text {
    width: 100%;
    height: 100%;
    background: var(--main-color);
    border-radius: 31px;
    white-space: pre-wrap;
    text-align: center;
    line-height: 44px;
    font-size: 18px;
    color: #ffffff;
  }
}
// 商城主页购物车按钮悬浮样式
.cart-ball {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  bottom: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.07);
}
// 会员主页基础组件基本样式
.st-member-index {
  margin: 0 20px;
  width: calc(100% - 40px);
  ::v-deep.member-msg::before {
    content: '';
    position: absolute;
    left: 12px;
    right: 12px;
    top: 0;
    border-top: 1px solid #f2f2f2;
  }
  &:last-child {
    ::v-deep.msg-box {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
}

.st-member-index[data-last='true'] {
  ::v-deep.msg-box {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  ::v-deep.member-msg::before {
    content: '';
    position: absolute;
    left: 12px;
    right: 12px;
    top: 0;
    border-top: none;
  }
}

// 会员主页底部按钮样式
.member-index-bottom {
  padding: 13px 20px;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 70px;
  background: #ffffff;
  display: flex;
  align-items: center;
  .bottom-left {
    flex: 1;
    flex-shrink: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .btn-item + .btn-item {
      margin-left: 26px;
    }
    .btn-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-icon {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
      .item-text {
        font-size: 12px;
        color: #808080;
        line-height: 12px;
      }
    }
  }
  .bottom-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    background: var(--main-color);
    border-radius: 999rem;
  }
}

.member-index-bottom-fixed {
  position: absolute;
  right: 16px;
  bottom: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  z-index: 20;
  .item-icon {
    width: 32px;
    height: 32px;
  }
}

.activity-bottom {
  display: flex;
  margin-top: $space;

  .activity-bottom-img {
    width: 375px;
  }
}
</style>

<style lang="scss">
// 底部水印样式
.mark-item {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mark-icon {
    height: 100%;
  }
  .mark-content {
    font-size: 11px;
    color: #999999;
    line-height: 11px;
  }
}
.mark-item + .mark-item {
  margin-top: 5px;
}

.me-bg-content {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.me-bg {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .me-bg-image-box {
    position: absolute;
    width: 375px;
    height: 100%;
    background-size: 100% auto;
    background-repeat: repeat-y;

    .me-bg-image {
      width: 100%;
    }
  }
}

// 个人中心底部固定样式
.me-page-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11px;
  .user-outlog + .user-outlog {
    margin-top: 11px;
  }
  .user-outlog {
    width: calc(100% - 12px * 2);
    height: 64px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*color: #353535;*/
    line-height: 16px;
    .handle-icon {
      width: 24px;
      height: 24px;
    }
  }
  .bottom-watermark {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    padding: 73px 0 21px;
  }
}

/* 页面设置-顶部样式设置 */
.header-fixed {
  width: 375px;
  position: absolute;
  z-index: 0;
  top: 0;

  .page-bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    overflow: hidden;

    .el-image {
      width: 376px;
      position: absolute;
      /*bottom: 0;*/
      left: 50%;
      margin-left: -188px;
    }
  }

  .default-back-icon {
    position: absolute;
    top: 33px;
    left: 11px;
    width: 20px;
    height: 20px;
  }

  .template-back-icon {
    position: absolute;
    top: 26px;
    left: 5px;
    width: 78px;
    height: 32px;
  }

  .link-dialog1 {
    width: 129px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;
    padding: 0 11px;

    .link-item1 {
      padding: 13px 0;
      display: flex;
      align-items: center;
      .link-image1 {
        width: 20px;
        height: 20px;
        margin-right: 9px;
      }
      .link-name1 {
        font-size: 14px;
        color: #111111;
      }
    }

    .link-item1 + .link-item1 {
      border-top: 1px solid #d3d3d3;
    }
  }

  .link-dialog2 {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 13px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;

    .link-item2 {
      text-align: center;
      flex: 0 0 25%;
      margin-bottom: 13px;
      .link-image2 {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
      .link-name2 {
        font-size: 14px;
        line-height: 14px;
        color: #111111;
      }
    }
  }

  .back-icon {
    width: 100%;
    height: 100%;
  }

  .el-image {
    width: 100%;
    height: 100%;
  }
}
</style>
