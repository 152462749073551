<template>
  <div
    class="article"
    :style="[
      myRootStyle,
      {
        borderRadius: myConfig.normal.borderRadius + 'px',
        '--title-color': myConfig.listStyle.titleColor || 'black',
        '--time-color': myConfig.listStyle.timeColor || '#979da6',
      },
    ]"
  >
    <StListTitle
      :style="{
        padding: `${myConfig.normal.colPadding}px ${myConfig.normal.rowPadding}px 16px`,
      }"
      :config="myConfig"
    />
    <div
      v-loading="loading"
      class="article-list"
      :style="{
        padding: `${myConfig.listTitle.isShow ? '0' : '12px'} ${myConfig.normal.rowPadding}px ${myConfig.normal.colPadding}px`,
      }"
    >
      <template v-for="(item, index) in dataList">
        <div
          class="list-item"
          :class="[
            myStyleType,
            myConfig.templateData.dataSpacing ? '' : 'nearest',
          ]"
          :style="{
            marginTop:
              index === 0 ? '0' : myConfig.templateData.dataSpacing + 'px',
            borderRadius:
              myStyleType === 'template2'
                ? myConfig.templateData.borderRadius + 'px'
                : 0,
            backgroundColor: myConfig.listStyle.bgColor
          }"
          :key="index"
        >
          <div class="text" :class="[myStyleType]" v-if="item.type === 0">
            <div class="text-title">
              <span v-if="item.item_type" class="is-special">专题</span
              >{{ item.title }}
            </div>
            <div class="text-public">
              <span>{{ item.source_name }}</span
              ><span>{{ item.post_time }}</span>
            </div>
          </div>
          <!-- 如果是模板二，没有三图显示 -->
          <div
            v-if="
              (item.type === 1 &&
                (!item.images || (item.images && item.images.length < 3)) &&
                myStyleType === 'template1') ||
              (myStyleType === 'template2' && item.type === 1)
            "
            class="image-text"
            :style="{
              flexDirection:
                myConfig.listStyle.imageText === 'left' ? 'row' : 'row-reverse',
            }"
          >
            <div
              class="video-images"
              :class="[myStyleType]"
              :style="{
                marginRight:
                  myConfig.listStyle.imageText === 'left'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                marginLeft:
                  myConfig.listStyle.imageText === 'right'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                background: item.image.url ? '' : '#f3f5f7',
                borderRadius: myConfig.templateData.borderRadius + 'px',
              }"
            >
              <img
                v-if="!item.image.url"
                class="default-icon"
                src="../../assets/images/mini-default-icon@2x.png"
              />
              <!--              <el-image v-else style="width: 100%;height: 100%;" :src="fixImageUrl(item.image.url)" fit="cover" />-->
              <img
                v-else
                style="width: 100%; height: 100%; object-fit: cover"
                crossorigin="anonymous"
                :src="fixImageUrl(item.image.url, {}, randomId + '_' + index)"
              />
            </div>
            <!-- <img class="images" src="../../assets/images/mini-default-icon@2x.png" fit="cover" /> -->
            <div class="texts" :class="[myStyleType]">
              <div class="text-title" style="height: 40px">
                <span v-if="item.item_type" class="is-special">专题</span
                >{{ item.title }}
              </div>
              <div class="text-public">
                <span>{{ item.source_name }}</span
                ><span>{{ item.post_time }}</span>
              </div>
            </div>
          </div>
          <!-- 模板一有三个封面才显示三图模式 -->
          <div
            v-if="
              item.type === 1 &&
              item.images &&
              item.images.length === 3 &&
              myStyleType === 'template1'
            "
            class="image-text"
            :style="{
              flexDirection:
                myConfig.listStyle.imageText === 'left' ? 'row' : 'row-reverse',
            }"
          >
            <!-- <div
              class="video-images"
              :class="[myStyleType]"
              :style="{
                marginRight:
                  myConfig.listStyle.imageText === 'left'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                marginLeft:
                  myConfig.listStyle.imageText === 'right'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                background: item.image.url ? '' : '#f3f5f7',
                borderRadius: myConfig.templateData.borderRadius + 'px',
              }"
            >
              <img
                v-if="!item.image.url"
                class="default-icon"
                src="../../assets/images/mini-default-icon@2x.png"
              />
         
              <img
                v-else
                style="width: 100%; height: 100%; object-fit: cover"
                crossorigin="anonymous"
                :src="fixImageUrl(item.image.url, {}, randomId + '_' + index)"
              />
            </div> -->
            <div class="texts" :class="[myStyleType]">
              <div class="text-title" style="margin-bottom: 8px">
                <span v-if="item.item_type" class="is-special">专题</span
                >{{ item.title }}
              </div>
              <div class="image-list">
                <div class="img-box" v-for="(el, imageI) in item.images">
                  <img
                    v-if="!el.url"
                    class="default-icon"
                    src="../../assets/images/mini-default-icon@2x.png"
                  />

                  <img
                    v-else
                    style="width: 100%; height: 100%; object-fit: cover"
                    crossorigin="anonymous"
                    :src="fixImageUrl(el.url, {}, randomId + '_' + index)"
                  />
                </div>
              </div>
              <div class="text-public">
                <span>{{ item.source_name }}</span
                ><span>{{ item.post_time }}</span>
              </div>
            </div>
          </div>
          <div class="large-image" v-if="item.type === 2">
            <div class="text-title">
              <span v-if="item.item_type" class="is-special">专题</span
              >{{ item.title }}
            </div>
            <div
              class="large-images"
              :style="{
                background: item.image.url ? '' : '#f3f5f7',
                borderRadius: myConfig.templateData.borderRadius + 'px',
              }"
            >
              <img
                v-if="!item.image.url"
                class="default-icon"
                src="../../assets/images/mini-default-icon@2x.png"
              />
              <!--              <el-image v-else style="width: 100%;height: 100%;" :src="fixImageUrl(item.image.url)" fit="cover" />-->
              <img
                v-else
                style="width: 100%; height: 100%; object-fit: cover"
                crossorigin="anonymous"
                :src="
                  fixImageUrl(
                    item.image.url,
                    { w: 500 },
                    randomId + '_' + index
                  )
                "
              />
            </div>
            <div class="text-public" style="margin-top: 10px">
              <span>{{ item.source_name }}</span
              ><span>{{ item.post_time }}</span>
            </div>
          </div>
          <div
            class="image-text"
            :style="{
              flexDirection:
                myConfig.listStyle.video === 'left' ? 'row' : 'row-reverse',
            }"
            v-if="item.type === 3 && myConfig.listStyle.video !== 'big'"
          >
            <div
              class="video-images"
              :style="{
                marginRight:
                  myConfig.listStyle.video === 'left'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                marginLeft:
                  myConfig.listStyle.video === 'right'
                    ? myStyleType === 'template2'
                      ? '12px'
                      : '18px'
                    : '',
                background: item.video.thumbnail ? '' : '#f3f5f7',
              }"
            >
              <img
                class="default-icon"
                src="../../assets/images/video-play.png"
              />
              <!--              <el-image class="video-img" v-if="item.video.thumbnail" style="width: 100%;height: 100%;"-->
              <!--                        :src="fixImageUrl(item.video.thumbnail)" fit="cover" />-->
              <img
                class="video-img"
                v-if="item.video.thumbnail"
                style="width: 100%; height: 100%; object-fit: cover"
                crossorigin="anonymous"
                :src="fixImageUrl(item.video.thumbnail, {}, true)"
              />
            </div>
            <div class="texts">
              <div class="text-title">{{ item.title }}</div>
              <div class="text-public">
                <span>{{ item.source_name }}</span
                ><span>{{ item.post_time }}</span>
              </div>
            </div>
          </div>
          <div
            class="large-image"
            v-if="item.type === 3 && myConfig.listStyle.video === 'big'"
          >
            <div class="text-title">{{ item.title }}</div>
            <div
              class="large-images"
              :style="{
                background: item.video.thumbnail ? '' : '#f3f5f7',
                borderRadius: myConfig.templateData.borderRadius + 'px',
              }"
            >
              <img class="play" src="../../assets/images/video-play.png" />
              <!--              <el-image class="video-img" v-if="item.video.thumbnail" style="width: 100%;height: 100%;"-->
              <!--                        :src="fixImageUrl(item.video.thumbnail)" fit="cover" />-->
              <img
                class="video-img"
                v-if="item.video.thumbnail"
                style="width: 100%; height: 100%; object-fit: cover"
                crossorigin="anonymous"
                :src="fixImageUrl(item.video.thumbnail, {}, true)"
              />
              <!-- <img class="images" src="../../assets/images/mini-default-icon@2x.png" fit="cover" /> -->
            </div>
            <div class="text-public" style="margin-top: 10px">
              <span>{{ item.source_name }}</span
              ><span>{{ item.post_time }}</span>
            </div>
          </div>
        </div>
      </template>
      <div
        class="load-more"
        v-if="myConfig.loadingConfig.loadingType === 'handle'"
      >
        <div :class="[myConfig.loadingConfig.handleType]">
          {{ myConfig.loadingConfig.handleText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const articleLists = require('../../assets/jsons/default-list.json').articleList

import { articleList } from '../../api/components'

import componentMixin from '../../mixins/component-mixin'

export default {
  name: 'StArticleList',
  mixins: [componentMixin],
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          rowPadding: 16,
          colPadding: 12,
          borderRadius: 4,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 15,
          borderRadius: 4,
        },
        dataConfig: {
          dataType: 'filter',
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
          dataAll: 1,
          dataFilter: [''],
          type: [1],
          isShowTopic: 0,
          topicType: 1,
          dataShowType: ['self_data', 'gov_data'],
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listStyle: {
          text: 1,
          imageText: 'left',
          largeImage: 1,
          video: 'big',
          bgColor: "",
          borderRadius: 0, // 暂时没用，但保留。在 templateData 那里有一个了
          titleColor: "#000",
          timeColor: ""
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '文章列表',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      articleList: [],
      dataList: [],
      loading: false,
    }
  },
  watch: {
    'myConfig.dataConfig.type'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getArticleList(false)
      }
    },
    'myConfig.dataConfig.dataShowType'(val) {
      this.getArticleList(false)
    },
    'myConfig.dataConfig.isShowTopic'(val) {
      this.getArticleList(false)
    },
    'myConfig.dataConfig.topicType'(val) {
      this.getArticleList(false)
    },
    'myConfig.loadingConfig.limit'(val) {
      this.getArticleList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getArticleList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getArticleList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getArticleList(false)
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getArticleList(false)
      }
    },
  },
  created() {
    this.getArticleList()
  },
  methods: {
    // 获取文章列表
    getArticleList(notCancel) {
      this.loading = true
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        category_all: this.myConfig.dataConfig.dataAll,
        category_names: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        type: this.myConfig.dataConfig.type,
        is_show_subject: this.myConfig.dataConfig.isShowTopic,
        subject_type: this.myConfig.dataConfig.topicType,
        data_sources: this.myConfig.dataConfig.dataShowType,
        ids,
      }
      articleList(requestData, notCancel)
        .then((res) => {
          if (res.data.length) {
            this.dataList = res.data
          } else {
            this.dataList = articleLists
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

img {
  object-fit: cover;
}

.article {
  background-size: 100% auto;
  background-repeat: repeat-y;
}
.article-list {
  min-height: 100px;
  // background-color: #fff;
  border-radius: 4px;
  .list-item {
    &.template2 {
      background-color: #fff;
      padding: 12px;

      &.nearest + .nearest {
        position: relative;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 12px;
          width: calc(100% - 12px * 2);
          height: 1px;
          background-color: $border-color;
        }
      }

      &.nearest:not(:nth-last-child(2)) {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .image-text {
      display: flex;
      justify-content: space-between;
      .images {
        width: 95px;
        height: 71px;
        border-radius: 4px;
      }

      .video-images {
        position: relative;
        flex-shrink: 0;
        width: 95px;
        height: 71px;
        border-radius: 4px;
        overflow: hidden;

        &.template2 {
          width: 145px;
          height: 82px;
        }
      }
    }

    .large-image {
      .large-images {
        position: relative;
        width: 100%;
        margin-top: 12px;
        overflow: hidden;

        &::before {
          content: '';
          padding-top: 56.25%;
          display: block;
        }

        .el-image,
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .images {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.default-icon {
  position: absolute;
  width: 28px !important;
  height: 28px !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: -14px;
  margin-left: -14px;
  z-index: 2;
}

.play {
  position: absolute !important;
  width: 40px !important;
  height: 40px !important;
  // margin-left: calc(50% - 20px);
  // margin-top: calc(90px - 20px);
  top: 50% !important;
  left: 50% !important;
  margin-top: -20px !important;
  margin-left: -20px !important;
  z-index: 2;
}

.text-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--title-color);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  .is-special {
    display: inline-block;
    margin-right: 5px;
    padding: 2px 3px;
    border-radius: 2px;
    border: 1px solid #979da6;
    font-size: 11px;
    color: #979da6;
    line-height: 11px;
  }
}
.text-public {
  margin-top: 15px;
  font-size: 12px;
  color: var(--time-color);
  line-height: 12px;
  @include clamp(1);
  span + span {
    margin-left: 8px;
  }
}

.text.template2 .text-public {
  margin-top: 24px;
  height: 18px;
  line-height: 18px;
}

.texts {
  width: 100%;

  &.template2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text-public {
      height: 18px;
      line-height: 18px;
      margin-top: 0;
    }
  }
  .image-list {
    display: flex;

    .img-box {
      flex: 1;
      height: 71px;
      border-radius: 4px;
      overflow: hidden;
    }
    .img-box + .img-box {
      margin-left: 8px;
    }
  }
}
.video-img {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
